import React, { useState, useEffect } from 'react';
import Footer from '../LandingPage/Footer';
import AdminNav from './AdminNav';
import Worshipp from '../images/worship-2.jpg';
import { useSelector } from 'react-redux';
import { Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import axios from 'axios';
import BaseUrl from '../BaseUrl';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const { loginAdmin: user } = useSelector((state) => state.admin);

    const [members, setMembers] = useState([]);
    const [prayer, setPrayer] = useState([]);
    const [testimony, setTestimony] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [childrenValues, setChildrenValues] = useState([]);
    const [adultValues, setAdultValues] = useState([]);
    const [barLabels, setBarLabels] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [membersData, prayerData, testimonyData, attendanceData] = await Promise.all([
                axios.get(BaseUrl + 'getAllUser'),
                axios.get(BaseUrl + 'getAllPray'),
                axios.get(BaseUrl + 'getAllTest'),
                axios.get(BaseUrl + 'getAttendance'),
            ]);

            setMembers(membersData.data.data);
            setPrayer(prayerData.data.data);
            setTestimony(testimonyData.data.data);
            setAttendance(attendanceData.data.data);
            processAttendanceData(attendanceData.data.data);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const processAttendanceData = (attendanceData) => {
        const sundayServiceData = attendanceData.filter(item => item.serviceType === 'Sunday Service');
        const formattedData = sundayServiceData.map(item => ({
            month: item.eventDate.split('-')[1],
            children: Number(item.children),
            adult: Number(item.adult),
            serviceType: item.serviceType
        }));

        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const aggregatedAttendance = formattedData.reduce((acc, item) => {
            const monthIndex = parseInt(item.month, 10) - 1;
            const month = months[monthIndex];
            if (!acc[month]) {
                acc[month] = { children: 0, adult: 0 };
            }
            acc[month].children += item.children;
            acc[month].adult += item.adult;
            return acc;
        }, {});

        const barLabels = Object.keys(aggregatedAttendance);
        const childrenValues = barLabels.map(month => aggregatedAttendance[month].children);
        const adultValues = barLabels.map(month => aggregatedAttendance[month].adult);

        setBarLabels(barLabels);
        setChildrenValues(childrenValues);
        setAdultValues(adultValues);
    };

    const totalMembers = members.length;
    const totalPrayer = prayer.length;
    const totalTestimony = testimony.length;

    return (
        <div>
            <AdminNav />
            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Hello, Mr {user?.fullName}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                <div>
                                    <p className="mb-0">Members</p>
                                    <p className="text-muted text-medium ft-sm mb-0"><em>{totalMembers}</em></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                <div>
                                    <p className="mb-0">Prayer Requests</p>
                                    <p className="text-muted text-medium ft-sm mb-0"><em>{totalPrayer}</em></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                <div>
                                    <p className="mb-0">Testimonials</p>
                                    <p className="text-muted text-medium ft-sm mb-0"><em>{totalTestimony}</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container mb-5'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Bar
                                data={{
                                    labels: barLabels,
                                    datasets: [
                                        {
                                            label: "Children",
                                            data: childrenValues,
                                            backgroundColor: [
                                                'rgba(255, 99, 132, 0.2)',
                                            ]
                                        },
                                        {
                                            label: "Adults",
                                            data: adultValues,
                                            backgroundColor: [
                                                'rgba(255, 205, 86, 0.2)',
                                            ]
                                        },
                                    ]
                                }}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Attendance Data'
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div style={{ width: '30%' }} className='col-lg-6 mx-auto'>
                            <Pie
                                data={{
                                    labels: ['Members', 'Prayer Requests', 'Testimonials'],
                                    datasets: [
                                        {
                                            data: [totalMembers, totalPrayer, totalTestimony],
                                            backgroundColor: [
                                                'rgba(255, 99, 132, 0.2)',
                                                'rgba(255, 159, 64, 0.2)',
                                                'rgba(75, 192, 192, 0.2)'
                                            ],
                                            borderColor: [
                                                'rgba(255, 99, 132, 1)',
                                                'rgba(255, 159, 64, 1)',
                                                'rgba(75, 192, 192, 1)'
                                            ],
                                            borderWidth: 1
                                        }
                                    ]
                                }}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Summary Data'
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                                                }
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Dashboard;
