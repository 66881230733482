import React, { useRef, useState } from 'react'
import Footer from '../LandingPage/Footer'
import Worshipp from '../images/worship-2.jpg'
import AdminNav from './AdminNav'
import { useNavigate } from 'react-router'
import BaseUrl from '../BaseUrl'
import axios from 'axios'

const AdminChangePassword = () => {

    const navigate = useNavigate();
    const email = useRef();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const HandleForgotten = (e)=>{
        e.preventDefault(e);
        setIsLoading(true);

        axios.post(BaseUrl + 'adminforgottenPassword', {email: email.current.value})
        .then((res)=>{
            if(res.data.status){
            }
            navigate(`/admin/admin-comfirmPassword?email=${email.current.value}`)
            setResult({
                message: res.data.message,
                status: res.data.status,
            });
            setTimeout(() => {
                setResult({
                    message: "",
                    status: false,
                });
                setError("");
            }, 3000);
        })
        .catch((err)=>{
            console.log(err);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }
  return (
    <div>
        <AdminNav/>
        <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Change Password</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="auth-section home-banner-2 vh-100 d-flex bg-light justify-content-center align-items-center"> */}
        <div className="container">
            <div className="row">
                <div className="col-lg-5 mx-auto">
                    <div className="card border-0 shadow-lg p-4 mb-5">
                        <div className="card-body">
                            <div className="mb-4 d-flex align-items-center justify-content-between">
                                <h3 className="mb-0 mx-auto">Change Password</h3>
                            </div>
                            <p>
                                        <b className="text-danger">{error}</b>
                                    </p>
                                    <div
                                        className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                            } d-${result.message ? 'block' : 'none'}`}>
                                        {result.message}
                                    </div>
                            <form action='' method='post' onSubmit={HandleForgotten}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="email" ref={email} required className="form-control" placeholder="Email address"/>
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                        <b>{isLoading ? "Loading..." : "Reset Password"}</b>
                                                    </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
    </div>



                </div>

        <Footer/>
    </div>
  )
}

export default AdminChangePassword