import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import { Link, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import Footer from './Footer'


const EventDetails = () => {

    const [news, setNews] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)
    const [eventUrl, setEventUrl] = useState('');
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        setIsLoading(true);

        axios.get(BaseUrl + `moreEvent/${searchParams.get('userId')}`)
            .then(res => {
                if (res.data.status) {
                    setNews(res.data.data);
                    setEventUrl(res.data.EventLink);

                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    const handleCopyUrl = () => {
        const urlToCopy = `${eventUrl}event-register?id=${news._id}`;
        navigator.clipboard.writeText(urlToCopy).then(() => {
            setCopySuccess('URL copied!');
            setTimeout(() => setCopySuccess(''), 2000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return (
        <div>
            <NavBar />
            {isLoading ? <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                <div className='spinner-grow text-dark '></div>
            </div> :
                <div className="main-content">
                    <div className="about-sect">
                        <div className="container">
                            <Link to="/events" class="text-dark text-medium d-inline-block mb-3">
                                <i className="bi bi-chevron-left"></i> Back
                            </Link>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img src={news.userImageUrl} alt='fkjk' className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-section pb-5 mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <h5>{news.title}</h5>
                                    <p className=" " dangerouslySetInnerHTML={{ __html: news.message }}></p>
                                    <p>
                                        Click the link to register for the event. <br /><br />
                                        <a className='bg-white text-primary border p-2' href={`${eventUrl}event-register?id=${news._id}`}>
                                            {`${eventUrl}event-register?id=${news._id}`}
                                        </a>
                                        <i className='fa fa-copy ml-2 fw-bold text-dark' onClick={handleCopyUrl} style={{ cursor: 'pointer' }}></i>
                                        {copySuccess && <span className='ml-2 text-success'>{copySuccess}</span>}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />

        </div>
    )
}

export default EventDetails