import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Worshipp from '../images/worship-2.jpg'
import AdminNav from './AdminNav'
import moment from 'moment'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { parseISO, formatDistanceToNow } from 'date-fns';


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}

const AddAdmin = () => {

    const admin = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [adminDepart, setAdminDepart] = useState([])

    useEffect(() => {
        FetchData();
    }, [])
    // fecthing all the department
    const FetchData = () => {
        axios.get(BaseUrl + "getAllAdmin")
            .then(data => {
                const information = data.data.data
                setAdminDepart(information);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const [searchMember, setSearchMember] = useState('');
    const handleMember = (e) => {
        setSearchMember(e.target.value);
    };
    // const filteredDepartment = adminDepart.filter((member) =>
    //     member.admin.toLowerCase().includes(searchMember.toLowerCase())
    // )
    const filteredDepartment = adminDepart.filter((member) =>
    member.admin && member.admin.toLowerCase().includes(searchMember.toLowerCase())
)


    const HandleSubmit = (e) => {
        e.preventDefault(e);
        const info = {
            admin: admin.current.value,
            joinDate: moment().format('YYYY-MM-DD')
        }

        if (editID) {
            axios.patch(BaseUrl + `editAdmin/${editID}`, info)
                .then((res) => {
                    if (res.data.status) {
                        FetchData();
                        setUser({
                            admin: "",
                        });
                        setEditID('')

                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch((err) => {
                    console.log(err.message);
                })

        } else {
            // adding department
            setIsLoading(true);
            axios.post(BaseUrl + "AddAdmin", info).then
                (res => {
                    if (res.data.status) {
                        FetchData();
                        admin.current.value = "";
                        setUser({
                            admin: "",
                        });
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch(err => {
                    console.log(err.message)
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    // editing department
    // const [user, setUser] = useState({ admin: "" });

    const [user, setUser] = useState([]);

    const [editID, setEditID] = useState('');
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }

    // const EditAdmin = (_id) => {
    //     setEditID(_id);
    //     if (_id) {
    //         setUser(adminDepart.find((val) => val._id === _id) || { admin: "" });
    //     }
        // setUser(() => adminDepart.find((val) => val._id === _id));
    // }
    
    const EditAdmin = (_id) => {
        setEditID(_id)
        setUser(() => adminDepart.find((val) => val._id == _id))
    }

    // deleting department
    const DeleteAdmin = (_id) => {
        axios.delete(BaseUrl + `removeAdmin/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Add Admin</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                {/* adding department */}
                                {/* <div className='d-flex mx-auto text-center'>
                                    <form method="post" action="" onSubmit={HandleSubmit}>
                                        <input type='text' name='admin' ref={admin} onChange={handleChange} value={user?.admin} required placeholder='Enter Admin' className='form-control w-100' /><br />
                                        <button type="submit" disabled={isLoading} name="submit" className="btn btn-main-1  text-medium rounded-0 py-3 px-4" >
                                            <b>
                                                {isLoading ? "Loading..." : "Add Admin"}
                                            </b>
                                        </button>
                                    </form>
                                </div> */}

<form method="post" action="" onSubmit={HandleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-floating ">
                                        <input type='text' name='admin' ref={admin} onChange={handleChange} value={user?.admin} required placeholder='Enter Admin' className='form-control'/>                                                
                                                <label>Add Admin</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center mt-3">
                                            <button type="submit" disabled={isLoading} name="submit" className="btn btn-main-1  text-medium rounded-0 py-3 px-4" >
                                            <b>
                                                {isLoading ? "Loading..." : "Submit"}
                                            </b>
                                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-lg-6">
                                <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for Admin' value={searchMember} onChange={handleMember} className='w-50 mx-auto centered-placeholder form-control m-2' /><br />
                                <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S/N</th>
                                                <th scope="col">Department</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredDepartment?.map((post, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{post.admin}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            onClick={() => EditAdmin(post._id)}
                                                            className="btn btn text-success white mr-2"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => DeleteAdmin(post._id)}
                                                            className="btn btn text-danger white ml-1"
                                                        >
                                                            <b><i className="fa fa-trash"></i></b>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <footer className="footer text-white py-5" style={{ backgroundColor: "#222123" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                            <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                            <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                            <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                            <ul className="list-unstyled">
                                <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                                <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                            <p className="mb-0">P.O. Box 18160</p>
                            <p>Richmond, VA 23226</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                                <ul className="list-unstyled d-flex mb-0" style={{ gap: "10px" }}>
                                    <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    )
}

export default AddAdmin