import React from "react";
import { Link } from "react-router-dom";

const DonationReceived = () => {
    const styles = {
        body: {
            margin: 0,
            fontFamily: 'Arial, sans-serif',
            background: 'linear-gradient(135deg, #f9f9f9, #e0e0e0)',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        container: {
            background: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            padding: '40px',
            maxWidth: '400px',
            width: '100%',
        },
        heading: {
            color: '#4CAF50',
            fontSize: '24px',
            marginBottom: '20px',
        },
        note: {
            color: '#777',
            fontSize: '14px',
            marginBottom: '30px',
        },
        paragraph: {
            color: '#555',
            fontSize: '16px',
            marginBottom: '30px',
        },
        button: {
            display: 'inline-block',
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            textDecoration: 'none',
            fontWeight: 'bold',
            transition: 'background-color 0.3s',
        },
        buttonHover: {
            backgroundColor: '#45a049',
        }
    };

    return (
        <div style={styles.body}>
            <div style={styles.container}>
                <h4 style={styles.heading}>
                    <i className="fas fa-check-circle"></i> Donation Received!!!
                </h4>
                <p style={styles.paragraph}>
                    Thank you for donating for the good cause. God bless you!!
                </p>
                <p style={styles.note}>
                    Please check your inbox for a copy of your receipt.
                </p>
                <Link to="/giving" style={styles.button}>
                    <i className="fas fa-undo"></i> Donate Again
                </Link>
            </div>
        </div>
    );
};

export default DonationReceived;
