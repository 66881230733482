import React, { useEffect, useRef, useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import moment from 'moment'
import { parseISO, formatDistanceToNow } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Footer from '../LandingPage/Footer'
import PastorNav from './PastorNav'


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}



const PastorSermon = () => {

    const title = useRef();
    const youtube = useRef();
    const message = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [editID, setEditID] = useState('')

    const [sermon, setSermon] = useState([])
    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        axios.get(BaseUrl + 'getSermon')
            .then(response => {
                if (response.data && Array.isArray(response.data.data)) {
                    setSermon(response.data.data);
                } else {
                    console.error('Invalid data format received from server:', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };


    const handleSubmit = () => {
        const data = {
            title: title.current.value,
            youtube: youtube.current.value,
            message: message.current.getEditor().root.innerHTML,
        }
        const joinDate = moment().format('YYYY-MM-DD');
        const updatedValues = { ...data, joinDate: joinDate };
        setIsLoading(true);
        if (editID) {
            axios.patch(BaseUrl + `EditSermon/${editID}`, updatedValues).then((res) => {
                if (res.data.status) {
                    FetchData();
                    setEditID('')

                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            }).catch((err) => {
                console.log(err.message);
            })
                .finally(() => {
                    setIsLoading(false);
                });

        } else {
            axios.post(BaseUrl + "adminSermon", updatedValues).then(
                res => {
                    if (res.data.status) {
                        FetchData();
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        // Reset user state after form submission
        setUser({});
        title.current.value = '';
        youtube.current.value = '';
        message.current.getEditor().root.innerHTML = '';
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })
    const [Loading, setLoading] = useState(false)

    const DeleteSermon = (_id) => {
        setLoading(true);
        axios.delete(BaseUrl + `delSermon/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();

                } else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // editing sermon

    const [user, setUser] = useState({});


    const inputIsChanging = (e) => {
        const name = e.target.name
        const value = e.target.value
        console.log(value)
        setUser({ ...user, [name]: value })
    }
    const EditSermon = (_id) => {

        // setEdit(true);
        setEditID(_id)
        setUser(() => sermon.find((val) => val._id == _id))
    }




    return (
        <div>
            <PastorNav />

            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Sermon</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className="row mt-5">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input name='title' type='text' className="form-control" onChange={inputIsChanging} value={user?.title} ref={title} placeholder="Sermon Title" />
                                            <label>Title</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input name='youtube' type='text' className="form-control" onChange={inputIsChanging} value={user?.youtube} ref={youtube} placeholder="Youtube" />
                                            <label>YouTube Link</label>
                                        </div>
                                        <div className="form-floating mb-5">
                                            <ReactQuill
                                                theme="snow"
                                                ref={message}
                                                value={user?.message || ''}
                                                onChange={(value) => inputIsChanging({ target: { name: 'message', value } })}
                                                style={{ height: '300px' }}
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['link', 'image'],
                                                        ['clean']
                                                    ],
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" onClick={handleSubmit} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                                <b>
                                                    {isLoading ? "Loading..." : "Submit"}
                                                </b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div style={{ height: '300px' }} className="overflow-auto bg-light p-lg-4 p-3 rounded">
                                    <h5 className="text-center mb-5">Word Of God</h5>
                                    <hr />
                                    {sermon.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post) => (

                                        <div className="mb-3 border-bottom">
                                            <h6>{post.title}</h6>
                                            <p className="line-clamp-3 ft-lg " dangerouslySetInnerHTML={{ __html: post.message }}></p>
                                            <p className="text-muted text-medium ft-sm"><em>Youtube Link: {post.youtube}</em></p>
                                            <p className="text-muted text-medium ft-sm"><em>Published On: {post.joinDate}</em></p>
                                            <button type="submit" disabled={Loading} name="submit" onClick={() => DeleteSermon(post._id)} className="btn btn text-danger" >
                                                <b>
                                                    {Loading ? "..." : <i className="fa fa-trash"></i>}
                                                </b>
                                            </button>
                                            <button type="submit" name="submit" onClick={() => EditSermon(post._id)} className="btn btn text-success  mr-2" >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default PastorSermon;