import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import logo from '../images/logo.png'
import axios from 'axios';
import BaseUrl from '../BaseUrl';

const ConfirmPassword = () => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [isLoad, setLoad] = useState(true)

    const email = searchParams.get('email');

    const otpCode = useRef();
    const newPassword = useRef();
    const confirmPassword = useRef();

    const [result, setResult] = useState({
        message: "",
        status: false
    })

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoad(false); 
        }, 4000);
    
        return () => clearTimeout(timer);
      }, []);
    

    const resetPassword = (e) => {
        e.preventDefault();

        if (!otpCode.current.value || !newPassword.current.value || !confirmPassword.current.value) {
            setError("All fields are required");
            return;
        }

        if (newPassword.current.value !== confirmPassword.current.value) {
            setError("Passwords do not match");
            return;
        }

        setIsLoading(true);

        const info = {
            otpCode: otpCode.current.value,
            newPassword: newPassword.current.value,
            email
        };

        axios.post(BaseUrl + 'resetPassword', info)
            .then((res) => {
                if (res.data.status) {
                    navigate('/Login');
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                setError("An error occurred while resetting password");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div>
            <div className="auth-section home-banner-2 vh-100 d-flex bg-light justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mx-auto">
                            <div className="card border-0 shadow-sm p-4">

                                <div className="card-body">
                                    <Link className="navbar-brand d-block mb-4" to="/">
                                        <img src={logo} alt='skfv' className="img-fluid" style={{ height: '70px' }} />
                                    </Link>
                                    <div className="mb-4 d-flex align-items-center justify-content-between">
                                        <h3 className="mb-0">Reset Password</h3>
                                    </div>
                                    <p>
                                        {isLoad && (
                                            <div className='alert alert-warning text-black text-center'>
                                                <p>Check your email for the OTP code </p>
                                            </div>
                                        )}
                                        <b className="text-danger">{error}</b>
                                    </p>
                                    <div
                                        className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                            } d-${result.message ? 'block' : 'none'}`}>
                                        {result.message}
                                    </div>
                                    <form onSubmit={resetPassword}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-floating mb-3">
                                                    <input type="text" ref={otpCode} required className="form-control" placeholder="OTP Code" />
                                                    <label>OTP Code</label>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input type="password" ref={newPassword} required className="form-control" placeholder="Enter New Password" />
                                                    <label>New Password</label>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input type="password" ref={confirmPassword} required className="form-control" placeholder="Enter Confirm Password" />
                                                    <label>Confirm Password</label>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="text-center mt-4">
                                                    <button type="submit" disabled={isLoading} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                        <b>{isLoading ? "Loading..." : "Reset Password"}</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    {/* <Link to="/Login" className="text-dark d-block my-3">Remember password - Back to login</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPassword;

