import React, { useEffect, useRef, useState } from 'react'
import avatarMan from '../images/000.png'
import avatarWoman from '../images/0111.png'
import Worshipp from '../images/worship-2.jpg'
import AdminNav from './AdminNav'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { parseISO, formatDistanceToNow } from 'date-fns';
import Footer from '../LandingPage/Footer'


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}

const ITEMS_PER_PAGE = 5;


const AdminMember = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        getDepartment();
        FetchData();
        fetchMember();
    }, [])
    useEffect(() => {
        setFilter(searchTerm != '' ? members.filter((member) => member?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase())) : members
        )
    }, [searchTerm])



    // getting the member added to the department 
    const [section, setSection] = useState([])

    const getDepartment = async () => {
        setIsLoading(true)
        await axios.get(BaseUrl + 'getDepartment')
            .then(result => {
                setSection(result.data.data);
            })
            .catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false)
            })
    };

    //   getting all department in drop down
    const [depart, setDepart] = useState()
    const FetchData = () => {
        axios.get(BaseUrl + "getAllDepartment")
            .then(data => {
                const info = data.data.data
                setDepart(info);
            })
            .catch(err => {
                console.log(err);
            })
    }
    // fetching all member
    const [members, setMembers] = useState([])
    const fetchMember = async () => {
        await axios.get(BaseUrl + 'getAllUser')
            .then(data => {
                setMembers(data.data.data);
                setFilter(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    };
    // deleting department
    const DeleteDepartment = (_id) => {
        axios.delete(BaseUrl + `deleteDepartment/${_id.departmentId}/?unique=${_id.id}`)
            .then((res) => {
                if (res.data.status) {
                    getDepartment();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    //   editing department
    const [user, setUser] = useState(undefined)
    const [editID, setEditID] = useState('')

    const EditDepartment = (_id) => {
        setEditID(_id)
        const find = section.find((val) => val.id == _id.id && val.departmentId == _id.departmentId)
        setUser(find.departmentId)
        setSearchTerm(find.fullName);
    }

    // search for member 

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // const filteredMembers = members.filter((member) =>
    //     member?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    // )

    const [searchMember, setSearchMember] = useState('');
    const handleMember = (e) => {
        setSearchMember(e.target.value);
    };
    const filteredDepartment = section.filter((member) =>
        member?.fullName?.toLowerCase()?.includes(searchMember.toLowerCase()) ||
        member?.department?.toLowerCase()?.includes(searchMember.toLowerCase())
    )

    // handleDepartment adding the deparment
    const [Error, setError] = useState("")

    const [result, setResult] = useState({
        message: "",
        status: false
    })
    const handleDepartment = (_id) => {
        if (isLoading) return;
        if (!user) return setResult({ message: "Select your Departent", status: false });
        const filter = section.find((val) => val.id == _id && val.departmentId == user);
        if (filter) return setResult({ message: "user already added to this deparment", status: false });
        if (editID) {
            setIsLoading(true)
            const newdetail = depart.find((val) => val._id == user)
            axios.patch(BaseUrl + `EditAddMember/${editID.departmentId}`, { fullName: editID.fullName, id: editID.id, department: newdetail.department, departmentId: newdetail._id })
                .then((res) => {
                    if (res.data.status) {
                        getDepartment();
                        setUser({
                            department: "",
                        });
                        setEditID('')
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch((err) => {
                    console.log(err.message);
                })

        } else {
            const found = members.find((val) => val._id == _id)
            const data = { fullName: found.fullName, id: _id, department: depart.find((val) => val._id == user).department, departmentId: user }
            setIsLoading(true)
            axios.patch(BaseUrl + `addMember/${user}`, { department: data })
                .then((res) => {
                    if (res.data.status) {
                        getDepartment();
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    const [currentPage, setCurrentPage] = useState(1);

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return filteredDepartment.slice(startIndex, endIndex);
    };




    return (
        <div>
            <AdminNav />

            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Add Member To Department</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <form method="post" action="">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <select
                                                    name='department'
                                                    onChange={(e) => setUser(e.target.value)}
                                                    required
                                                    value={user?.department}
                                                    className='form-control my-2'
                                                >
                                                    <option value={''}>--Choose--</option>
                                                    {depart?.map((post) => (
                                                        <option key={post._id} value={post._id}>
                                                            {post.department}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label>Department</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <input type='text' name='search' placeholder='Search for Member' value={searchTerm} onChange={handleSearch} className='centered-placeholder form-control' /><br />
                                                <label>Search For Member</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="info-section pb-5 mt-5">
                                    <div className="row ">
                                        {filter.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post) => (
                                            <div className="col-lg-6">
                                                <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                                    <div className="position-relative">

                                                        <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                            <img
                                                                src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}
                                                                alt='sdbjh'
                                                                className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-2">{post.fullName}</p>
                                                        <em style={{ cursor: 'pointer' }} type="submit" name="submit" onClick={() => handleDepartment(post._id)} className="btn btn-sm text-medium ft-sm text-white bg-success">
                                                            <i className="fa fa-plus" ></i>
                                                        </em>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for Member' value={searchMember} onChange={handleMember} className='w-100 mx-auto centered-placeholder form-control m-2' /><br />
                                <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S/N</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Department</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getPaginatedData().map((post, index) => (
                                                <tr key={index}>
                                                    <td>{post.index}</td>
                                                    <td>{post.fullName}</td>
                                                    <td>{post.department}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            onClick={() => EditDepartment(post)}
                                                            className="btn btn text-success mr-1"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => DeleteDepartment(post)}
                                                            className="btn btn text-danger ml-1"
                                                        >
                                                            <b><i className="fa fa-trash"></i></b>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <nav>
                                        <ul className="pagination">
                                            {[...Array(Math.ceil(filteredDepartment.length / ITEMS_PER_PAGE)).keys()].map((num) => (
                                                <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
                                                        {num + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default AdminMember