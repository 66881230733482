import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import { useLocation } from 'react-router-dom';
import Footer from "../LandingPage/Footer";
import moment from 'moment';

const EventsRegister = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('id');

    const [eventStatus, setEventStatus] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        eventVerify();
    }, []);

    const eventVerify = async () => {
        try {
            const res = await axios.get(`${BaseUrl}eventVerify/${paramValue}`);
            if (res.data.status) {
                setEventStatus(res.data.event);
                // console.log(res.data.event);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setErrorMessage('Invalid verification.');
            } else {
                setErrorMessage('Error verifying.');
            }
        }
    };

    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState({
        message: "",
        status: false
    })
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        socialMedia: "",
    })
    const inputIsChanging = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }

    const HandleSubmit = (e) => {
        e.preventDefault(e);
        const joinDate = moment().format('YYYY-MM-DD');
        const userId = paramValue;
        const updatedValues = { ...user, joinDate: joinDate, userId:userId };
        setIsLoading(true);
        axios.post(BaseUrl + "EventsRegister", updatedValues).then(
            res => {
                if (res.data.status) {
                    setUser({
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        email: "",
                        socialMedia: "",
                    });
                }
                else {
                    setErrorMessage(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setErrorMessage("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });

    }

    // const [attendance, setAttendance] = useState([])

    // useEffect(() => {

    //     FetchAttendance();

    // }, [])

    // const FetchAttendance = () => {
    //     axios.get(BaseUrl + 'getEventRegister')
    //         .then(res => {
    //             setAttendance(res.data.data);
    //             console.log(res.data.data);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }




    return (
        <div>
            <div className="container m-5 mx-auto">
                <div className="mx-auto p-5">
                    {errorMessage && (
                        <p className="text-center text-danger">{errorMessage}</p>
                    )}
                            <div >
                                <h1 className="text-bold text-center custom-heading-3 mt-5 mb-3">
                                     {eventStatus.title}
                                </h1>
                                <p className="line-clamp-2 ft-lg text-center" dangerouslySetInnerHTML={{ __html: eventStatus.message }}></p>
                            </div>
                      
                    <div
                        className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                            } d-${result.message ? 'block' : 'none'}`}>
                        {result.message}
                    </div>

                    <form className="shadow-sm p-5" method="post" action="" onSubmit={HandleSubmit}>
                        <h3 className="text-bold custom-heading-3 mb-5">Registration</h3>
                        <div className="mb-4">
                            <label htmlFor="firstName" className="form-label">First name *</label>
                            <input type="text" id="firstName" name='firstName' placeholder="Enter First name" onChange={inputIsChanging} value={user.firstName} className="form-control form-control-size" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="lastName" className="form-label">Last name *</label>
                            <div className="input-group mb-0">
                                <input type="text" id="lastName" placeholder="Enter Last name" name='lastName' onChange={inputIsChanging} value={user.lastName} className="form-control form-control-size" />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phoneNumber" className="form-label">Phone number *</label>
                            <div className="input-group mb-0">
                                <input type="text" id="phoneNumber" placeholder="Enter Phone number" name='phoneNumber' onChange={inputIsChanging} value={user.phoneNumber} className="form-control form-control-size" />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="emailAddress" className="form-label">Email Address *</label>
                            <div className="input-group mb-0">
                                <input type="text" id="emailAddress" placeholder="Enter Email Address" name='email' onChange={inputIsChanging} value={user.email} className="form-control form-control-size" />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="form-label">How did you hear about us</label>
                            <select className="form-select form-control-size" name='socialMedia' required onChange={inputIsChanging} value={user?.socialMedia} >
                                <option value="">-- Select category --</option>
                                <option value="whatsapp">Whatsapp</option>
                                <option value="facebook">Facebook</option>
                                <option value="tiktok">Tiktok</option>
                            </select>
                        </div>
                        <div>
                            <button type="submit" name="submit" disabled={isLoading} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                Submit
                                {isLoading ? "Loading..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>



    );
};

export default EventsRegister;



