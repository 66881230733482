import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'

const AdminNav = () => {
    const navigate = useNavigate();

    const LogOut = () => {
        localStorage.removeItem("token");
        navigate("/Login", { replace: true })
    }
    

    return (
        <div>
            <div className="top-bar bg-color-2 py-1 fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-unstyled d-flex align-items-center justify-content-end mb-0" style={{ gap: '10px' }}>
                                <li><span style={{ cursor: 'pointer' }} onClick={LogOut} className="text-white text-decoration-none">Log out</span></li>
                                <li><span className="text-white">|</span></li>
                                <Link to="/admin/Donation" className="text-white text-decoration-none " ><span style={{ cursor: 'pointer' }}>Donate</span></Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white fixed-top nav-top-auto border-bottom">
                <div className="container">
                    <img src={logo} alt='jf' className="img-fluid" style={{ height: "70px" }} />
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav main-nav-item ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/admin/dashboard">Home</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Configuration
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/admin/Verification-Code">Verification Code</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/prophecies ">Personal Prophecies </Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/header-setting">Header Settings</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/gallery-setting ">Gallery Settings </Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Shout-Out
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/admin/prayer">Prayer Request</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/testimonies">Testimonial</Link>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Activities
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/admin/Admin-Sermon">Sermon</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/News">News</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/events ">Events </Link>
                                <Link className="nav-link" aria-current="page" to="/admin/Admin-Attendance">Attendance</Link>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Department
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/admin/Admin-Department">Add Department</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/AdminMember-Department">Add Member Department</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/add-admin-member">Add Member To Admin</Link>
                                </div>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/admin/consultation">Consultation</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Request
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/admin/All-Prayer-Request">All Prayer Request</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/All-Testimonies">All Testimonial</Link>
                                </div>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/admin/All-Members">Members</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Account Settings
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/admin/transactions">Transactions</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/Admin-Dashboard">Profile</Link>
                                    <Link className="nav-link" aria-current="page" to="/admin/change-password">Change Password</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default AdminNav