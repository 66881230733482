import React, { useEffect, useRef, useState } from 'react'
import AdminNav from './AdminNav'
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import Worshipp from '../images/worship-2.jpg'
import moment from 'moment';
import { Link } from 'react-router-dom';

const VerificationCode = () => {

    const verifyCodee = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [codee, setCodee] = useState([])

    useEffect(() => {
        FetchCode();
    }, [])

    const FetchCode = () => {
        axios.get(BaseUrl + "getVerifyCode")
            .then(data => {
                setCodee(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }


    const handleCode = (e) => {
        e.preventDefault(e);
        const info = {
            joinDate: moment().format('YYYY-MM-DD'),
            verifyCode: verifyCodee.current.value
        }
        if (editID) {
            axios.patch(BaseUrl + `EditCode/${editID}`, info)
                .then((res) => {
                    if (res.data.status) {
                        FetchCode();
                        setUser({
                            verifyCode: "",
                        });
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch((err) => {
                    console.log(err.message);
                })
                
            } else {
                if(codee.length > 0){
                    setResult({
                    message: 'The Verification has been created! Edit The Code That Has Been Created',
                    status: false
                })
                return
            }
            setIsLoading(true);

        axios.post(BaseUrl + "adminVerify", info).then(
            res => {
                if (res.data.status) {
                    FetchCode();
                    verifyCodee.current.value = '';
                    setUser({
                        verifyCode: "",
                    });
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }}

    const [result, setResult] = useState({
        message: "",
        status: false
    })

// editing department
const [user, setUser] = useState([])
const [editID,setEditID] = useState('')
const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setUser({ ...user, [name]: value })
}

const EditCode =(_id)=>{
    // console.log('sjdkjh');
    setEditID(_id)
    setUser(()=>codee.find((val)=>val._id==_id)) 
}

    // deleting department
    const DeleteCode =(_id)=>{
        axios.delete(BaseUrl + `deleteCode/${_id}` )
        .then((res)=>{
            if(res.data.status){
                FetchCode();
            }
        })
        .catch(err => {
            console.log(err.message)
        })
    }
    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Verification Code</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className='row'>
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <form method="post" action="">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                    <input type='text' name='verifyCode' onChange={handleChange} value={user?.verifyCode} required className='form-control' ref={verifyCodee} />
                                                <label>Verification Code</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" onClick={handleCode} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                        <b>
                                            {isLoading ? "Loading..." : "Submit"}
                                        </b>
                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className=' mx-auto text-center'>
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Verification Code</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {codee?.map((post, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{post.verifyCode}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                onClick={() => EditCode(post._id)}
                                                                className="btn btn  text-success"
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => DeleteCode(post._id)}
                                                                className="btn btn text-danger mr-2"
                                                            >
                                                                <b><i className="fa fa-trash"></i></b>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <footer className="footer text-white py-5" style={{ backgroundColor: "#222123" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                            <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                            <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                            <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                            <ul className="list-unstyled">
                                <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                                <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                            <p className="mb-0">P.O. Box 18160</p>
                            <p>Richmond, VA 23226</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                                <ul className="list-unstyled d-flex mb-0" style={{ gap: "10px" }}>
                                    <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default VerificationCode