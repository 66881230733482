import React, { useEffect, useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import Footer from '../LandingPage/Footer';
import BaseUrl from '../BaseUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SuperNav from './SuperNav';


const SuperTransaction = () => {
    const { LoginSuper: user } = useSelector((state) => state.super)
    const [transact, setTransact] = useState([]);

    useEffect(() => {
    
        FetchTransaction();
    }, [])

    const FetchTransaction = () => {
        axios.get(`${BaseUrl}v1/payments/history/${user.email} `)
            .then(response => {
                const transactions = response.data.data.transactions;
                setTransact(transactions);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <SuperNav />
            <div className="about-sect">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                <div className="overlay-hero"></div>
                                <h2 className="text-white z-index-1">Transactions History</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className="mb-2 text-center">Transaction History</h2>
            <div className="container mt-5">
                {transact.length === 0 ? (
                    <p>No transactions found.</p>
                ) : (
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Purpose</th>
                            </tr>
                        </thead>
                        <tbody>

                            {transact.map((transact, index) => (
                                <tr key={index}>
                                    <td>{transact.fullname}</td>
                                    <td>{transact.email}</td>
                                    <td>{transact.amount}</td>
                                    <td>{new Date(transact.createdAt).toLocaleDateString()}</td>
                                    <td>{transact.payment_type}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <Footer />
        </>
    )
}

export default SuperTransaction;