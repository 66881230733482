import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import Worshipp from '../images/worship-2.jpg';
import { useSelector } from 'react-redux';
import SuperNav from './SuperNav';


const SuperNotifications = () => {
    const { LoginSuper: user } = useSelector((state) => state.super)
    const [notifications, setNotifications] = useState([]);


    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
        
        const response = await axios.get(BaseUrl + `notifications/${user?._id}`)
            if (response.data.status) {
                setNotifications(response.data.data);
                console.log(response.data.data);
            } else {
                console.error("Failed to fetch notifications.");
            }
        } catch (err) {
            console.error("An error occurred while fetching notifications:", err);
        }
    };

    const Delete = (_id) => {
        axios.delete(BaseUrl + `removeNotification/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    fetchNotifications();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    return (
        <div>
            <SuperNav />
            <div className="main-content">
            <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Notifications</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h3 className="text-center mt-4">Notifications</h3>
                    <ul className="list-group">
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <li key={index} className="list-group-item">
                                    <p>message: &nbsp; {notification.fullName} &nbsp; 
                                    booked a consultation on {notification.date} &nbsp;
                                     from {notification.startTime} to {notification.endTime}
                                     <i className='text-danger fa fa-trash ml-3' onClick={() => Delete(notification._id)}></i>
                                    </p>
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item">No notifications available.</li>
                        )} 
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SuperNotifications;
