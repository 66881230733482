import React, { useEffect, useState } from 'react'
// import UserNav from './UserNav'
import avatarMan from '../images/000.png'
import avatarWoman from '../images/0111.png'
import Worshipp from '../images/worship-2.jpg'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Footer from '../LandingPage/Footer'
import SuperNav from './SuperNav'

const BirthdayCelebrant = () => {

    const [birthday, setBirthday] = useState([])
    const { LoginSuper: user } = useSelector((state) => state.super)

    // const { loginUser: user } = useSelector((state) => state.login)


    useEffect(() => {

        fetchData();

    }, []);

    const fetchData = async () => {
        await axios.get(BaseUrl + 'getBirthday')
            .then(res => {
                if (res.data.status) {
                    const currentMonth = new Date().getMonth();
                    const filteredData = res.data.data.filter(item => {
                        const itemMonth = new Date(item.Dob).getMonth();
                        return itemMonth === currentMonth;
                    });
                    setBirthday(filteredData);
                    // console.log(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    };
    const handleMobileClick = (mobile) => {
        window.location.href = `tel:${mobile}`
    }
    const handleEmailClick = (email) => {
        window.location.href = `mailto:${email}`
    }
    const formattedJoinDate = moment(birthday.Dob).format('MM-DD');

    return (
        <div>
            <SuperNav />
            <div className="">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Birthday Celebrants</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="alert border-0 alert-warning alert-dismissible fade show" role="alert">
                            <strong>We have  {birthday.length} birthday celebrants this month. </strong>
                        </div>
                        <div className="row">

                            {birthday.filter((val) => val._id != user._id).map((post) => (
                                <div className="col-lg-4">
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <div className="position-relative">

                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                <img
                                                    src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}
                                                    alt='sdbjh'
                                                    className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                            </div>
                                        </div>
                                        <div>

                                            <p className="mb-0">{post.fullName}</p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>{formattedJoinDate}</em></p>

                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleMobileClick(post.mobile)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-phone"></i> Call
                                                </p>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleEmailClick(post.email)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-envelope"></i> Send a message
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default BirthdayCelebrant