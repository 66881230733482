import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminNav from './AdminNav';
import Worshipp from '../images/worship-2.jpg';
import BaseUrl from '../BaseUrl';
import Footer from '../LandingPage/Footer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ITEMS_PER_PAGE = 5;

const AdminConsultation = () => {
    const { loginAdmin: user } = useSelector((state) => state.admin);
    const [consultations, setConsultations] = useState([]);
    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [first, setFirst] = useState('');
    const [purpose, setPurpose] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        fetchConsultations();
        fetchNotifications();
    }, []);

    const fetchConsultations = async () => {
        try {
            const response = await axios.get(`${BaseUrl}getAdminConsultation/${user?._id}`);
            if (response.data.status) {
                setConsultations(response.data.data);
            } else {
                console.error("Failed to fetch consultation times.");
            }
        } catch (err) {
            console.error("An error occurred while fetching consultation times:", err);
        }
    };

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${BaseUrl}notifications/${user?._id}`);
            if (response.data.status) {
                setNotifications(response.data.data);
            } else {
                console.error("Failed to fetch notifications.");
            }
        } catch (err) {
            console.error("An error occurred while fetching notifications:", err);
        }
    };

    const handleAddConsultation = async () => {
        setIsLoading(true);
        const userId = user?._id;
        const fullName = user?.fullName;

        if (purpose && date && startTime && endTime) {
            const start = new Date(`2000-01-01T${startTime}`);
            const end = new Date(`2000-01-01T${endTime}`);
            const durationInHours = (end - start) / (1000 * 60 * 60);

            if (durationInHours > 6) {
                setFirst("Time interval cannot be more than 6 hours.");
                setTimeout(() => setFirst(null), 5000);
                setIsLoading(false);
                return;
            }

            const isDuplicate = consultations.some(consult =>
                consult.date === date &&
                (consult.startTime === startTime || consult.endTime === endTime) &&
                consult.purpose === purpose
            );

            if (isDuplicate) {
                setFirst("A consultation with the same purpose or time already exists.");
                setTimeout(() => setFirst(null), 5000);
                setIsLoading(false);
                return;
            }
            const newConsult = { purpose, date, startTime, endTime, fullName, userId, status: "Pending" };

            try {
                const response = await axios.post(`${BaseUrl}adminconsultations`, newConsult);
                if (response.data.status) {
                    setConsultations(prevConsultations => [...prevConsultations, newConsult]);
                    setPurpose('');
                    setDate('');
                    setStartTime('');
                    setEndTime('');
                } else {
                    console.error("Failed to add consultation time.");
                }
            } catch (err) {
                console.error("An error occurred while adding the consultation time:", err);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const formatTime = (time) => {
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        minutes = minutes.padStart(2, '0');
        return `${hours}:${minutes} ${period}`;
    };

    const handleDelete = async (_id) => {
        try {
            const response = await axios.delete(`${BaseUrl}removeConsult/${_id}`);
            if (response.data.status) {
                setConsultations(prevConsultations => prevConsultations.filter(consult => consult._id !== _id));
            }
        } catch (err) {
            console.error("An error occurred while deleting the consultation:", err);
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return consultations.slice(startIndex, endIndex);
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <div>
            <AdminNav />
            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Consultation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-5 mx-auto mb-lg-0 mb-4">
                            <h3 className="text-center mt-4">Set Consultation Time</h3>
                            <p className='text-danger fw-bold text-center'>{first}</p>
                            <div className="form-group">
                                <label>Purpose</label>
                                <select
                                    id="consultationPurpose"
                                    onChange={(e) => setPurpose(e.target.value)}
                                    value={purpose}
                                    name='purpose'
                                    className="form-control">
                                    <option value="">--Select--</option>
                                    <option value="marriage">Marriage</option>
                                    <option value="education">Education</option>
                                    <option value="finance">Finance</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="consultationDate">Date</label>
                                <input
                                    type="date"
                                    id="consultationDate"
                                    name='setDate'
                                    className="form-control"
                                    value={date}
                                    min={today}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="consultationStartTime">Start Time</label>
                                <input
                                    type="time"
                                    name='startTime'
                                    id="consultationStartTime"
                                    className="form-control"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="consultationEndTime">End Time</label>
                                <input
                                    type="time"
                                    name='endTime'
                                    id="consultationEndTime"
                                    className="form-control"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </div>
                            <button className="btn btn-main-1 w-100 text-medium rounded-sm py-3 px-4 mt-3" disabled={isLoading} onClick={handleAddConsultation}>
                                <b>
                                    {isLoading ? "Submitting..." : "Submit"}
                                </b>
                            </button>
                        </div>

                        <div className="col-lg-7 mx-auto">
                            {notifications.length > 0 && (
                                <Link to="/admin/notification" className="btn btn-success mr-auto text-medium rounded-sm mt-3">
                                    View Notifications
                                </Link>
                            )}

                            <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S/N</th>
                                            <th scope="col">Purpose</th>
                                            <th scope="col" colSpan={2}>Date</th>
                                            <th scope="col" colSpan={3}>Time</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {consultations.length > 0 ? (
                                            getPaginatedData().map((consultation, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{capitalizeFirstLetter(consultation.purpose)}</td>
                                                    <td colSpan={2}>{consultation.date}</td>
                                                    <td colSpan={3}>{formatTime(consultation.startTime)} <i>-<br /></i> {formatTime(consultation.endTime)}</td>
                                                    <td>
                                                        <i className={
                                                            consultation?.status === "Booked" ? 'text-white border bg-success rounded p-2' :
                                                                consultation?.status === "pending" ? 'text-white border bg-danger rounded p-1' :
                                                                    'text-white border bg-secondary rounded p-2'}>
                                                            {consultation?.status}
                                                        </i>
                                                    </td>

                                                    <td>
                                                        <i className='text-danger fa fa-trash ml-3' onClick={() => handleDelete(consultation._id)}></i>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9" className="text-center">No consultations scheduled.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-lg-12 mt-3">
                                <nav>
                                    <ul className="pagination">
                                        {[...Array(Math.ceil(consultations.length / ITEMS_PER_PAGE)).keys()].map((num) => (
                                            <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
                                                <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
                                                    {num + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AdminConsultation;
