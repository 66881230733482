import React, { useEffect, useState } from 'react'
import AdminNav from './AdminNav';
import Worshipp from '../images/worship-2.jpg'
import Footer from '../LandingPage/Footer';
import BaseUrl from '../BaseUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';


const AdminTransactions = () => {

    const { loginAdmin: user } = useSelector((state) => state.admin);
    const [transaction, setTransaction] = useState([]);
    const [transact, setTransact] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        FetchData();
        FetchTransaction();
    }, [])

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredTransaction = transaction.filter((transac) =>
        transac.fullname.toLowerCase().includes(searchTerm.toLowerCase())
    );
// fetching all traansaction
const FetchData = () => {
    axios.get(`${BaseUrl}v1/payments/histories`)
    .then(response => {
        const transactions = response.data.data.transactions;
        setTransaction(transactions);
    })
    .catch(err => {
        console.log(err);
    });
}

// fetching personal traansaction
    const FetchTransaction = () => {
        axios.get(`${BaseUrl}v1/payments/history/${user.email} `)
            .then(response => {
                const transactions = response.data.data.transactions;
                setTransact(transactions);
            })
            .catch(err => {
                console.log(err);
            });
    }


    return (
        <>
            <AdminNav />
            <div className="main-content">

            <div className="about-sect">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                <div className="overlay-hero"></div>
                                <h2 className="text-white z-index-1">Transactions History</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className="mb-2 text-center">Transaction History</h2>
            <div className="container mt-5">

            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li className="nav-item" role="presentation">
        <button className="nav-link active fw-bold" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Personal</button>
    </li>
    <li className="nav-item" role="presentation">
        <button className="nav-link fw-bold" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Congregation</button>
    </li>
</ul>



                <div className="tab-content mb-5" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                        <input type='text' name='search' placeholder='Search for a transaction' value={searchTerm} onChange={handleSearch} className='w-25 ml-auto text-center form-control mb-4 ' />
                        <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                            {transaction.length === 0 ? (
                                <p>No transactions found.</p>
                            ) : (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Purpose</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredTransaction.map((transaction, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{transaction.fullname}</td>
                                                <td>{transaction.email}</td>
                                                <td>{transaction.amount}</td>
                                                <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
                                                <td>{transaction.payment_type}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        {transact.length === 0 ? (
                            <p>No transactions found.</p>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Purpose</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {transact.map((transact, index) => (
                                        <tr key={index}>
                                            <td>{transact.fullname}</td>
                                            <td>{transact.email}</td>
                                            <td>{transact.amount}</td>
                                            <td>{new Date(transact.createdAt).toLocaleDateString()}</td>
                                            <td>{transact.payment_type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </>
    )
}

export default AdminTransactions