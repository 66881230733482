import React, { useRef, useState } from 'react'
import { Link,  useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'
import axios from 'axios';
import BaseUrl from '../BaseUrl';


const Forgotten = () => {
    const navigate = useNavigate();
    const email = useRef();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const HandleForgotten = (e)=>{
        e.preventDefault(e);
        setIsLoading(true);

        axios.post(BaseUrl + 'forgottenPassword', {email: email.current.value})
        .then((res)=>{
            if(res.data.status){
                navigate(`/Confirm-Password?email=${email.current.value}`)
            }
            setResult({
                message: res.data.message,
                status: res.data.status,
            });
            setTimeout(() => {
                setResult({
                    message: "",
                    status: false,
                });
                setError("");
            }, 3000);
        })
        .catch((err)=>{
            console.log(err);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }


  return (
    <div>
        <div className="auth-section home-banner-2 vh-100 d-flex bg-light justify-content-center align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 mx-auto">
                    <div className="card border-0 shadow-sm p-4">
                        <div className="card-body">
                            <Link className="navbar-brand d-block mb-4" to="/">
                                <img src={logo} alt='skfv' className="img-fluid" style={{height: '70px'}} />
                            </Link>
                            <div className="mb-4 d-flex align-items-center justify-content-between">
                                <h3 className="mb-0">Forgotten Password</h3>
                            </div>
                            <p>
                                        <b className="text-danger">{error}</b>
                                    </p>
                                    <div
                                        className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                            } d-${result.message ? 'block' : 'none'}`}>
                                        {result.message}
                                    </div>
                            <form action='' method='post' onSubmit={HandleForgotten}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="email" ref={email} required className="form-control" placeholder="Email address"/>
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            {/* <button type='submit' name='submit' className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">Reset password</button> */}
                                            <button type="submit" disabled={isLoading} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                        <b>{isLoading ? "Loading..." : "Reset Password"}</b>
                                                    </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <Link to="/Login" className="text-dark d-block my-3">Remember password - Back to login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Forgotten