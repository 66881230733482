import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import Worshipp from '../images/worship-2.jpg';
import Footer from '../LandingPage/Footer'
import PastorNav from './PastorNav';



const Details = () => {

    const [user, setUser] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        setIsLoading(true);

        axios.get(BaseUrl + `userDetail/${searchParams.get('userId')}`)
            .then(res => {
                if (res.data.status) {
                    setUser(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

   

    return (
        <div>
            <PastorNav />

            {isLoading ?  <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <div className='spinner-grow text-dark '></div>
                        
                    </div>:
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Profile</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="info-section pb-5">
                    <div className="container">
                    <Link to="/pastor/notification" class="text-dark text-medium d-inline-block mb-3">
                        <i className="bi bi-chevron-left"></i> Back
                    </Link>
                    
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h3 className="mb-0">Profile</h3>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Mr" value={user?.titles} disabled />
                                            <label>Title</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Full name" value={user?.fullName} disabled />
                                            <label>Full name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" placeholder="Email address" value={user?.email} disabled />
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Phone number" disabled value={user?.mobile} />
                                            <label>Phone number</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" value={user?.postalCode} className="form-control" placeholder="Postal code" disabled />
                                            <label>Postal code</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" value={user?.address} className="form-control" placeholder="Address" disabled />
                                            <label>Address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">

                                        <p className="mb-0"><label>Date of Birth</label></p>
                                        <span>{user?.Dob}</span>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Gender</label></p>
                                        <span>{user?.gender}</span>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Status</label></p>
                                        <span className="badge text-bg-success">Member</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
}
            <Footer/>
        </div>

    )
}

export default Details;