import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import BaseUrl from '../BaseUrl'
import NavBar from './NavBar'
// import avatar from '../images/avatar-1.jpeg'
// import banner from '../images/banner.jpeg'
// import YouTube from 'react-youtube';
import moment from 'moment'
import Footer from './Footer'
import SermonComment from './SermonComment';

const SermonDetails = () => {

    const [sermon, setSermon] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [youtube, setYoutube] = useState("")
    const [replyId, setReplyId] = useState('')
    const [reLoadReply, setReLoadReply] = useState('')
    const [isLoad, setIsLoad] = useState(false)



    useEffect(() => {
        FetchData();
        // FetchComment();
    }, [])

    const FetchData = () => {
        setIsLoad(true)
        axios.get(BaseUrl + `moreSermon/${searchParams.get('userId')}`)
            .then(res => {
                if (res.data.status) {
                    setSermon(res.data.data);
                    const params = res.data.data.youtube.split('=');
                    setYoutube(params[1])
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoad(false);
            });
    }
    // getting the comment
    const [comment, setComment] = useState([])
    const FetchComment = () => {
        axios.get(BaseUrl + `getUserComment/${sermon._id}`)
            .then(res => {
                if (res.data.status) {
                    console.log(res.data.data);
                    setComment(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        FetchComment()
    }, [sermon])





    // sending the comment

    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const [user, setUser] = useState({
        fullName: "",
        email: "",
        mobile: "",
        message: "",
    })

    const inputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }

    const HandleSubmit = (e) => {
        e.preventDefault(e);
        const joinDate = moment().format('YYYY-MM-DD');
        const userId = sermon._id;
        const updatedValues = { ...user, joinDate: joinDate, userId: userId };

        if (replyId) {
            axios.post(BaseUrl + 'replyComment', { ReplyId: replyId, ...updatedValues }).then((res) => {
                if (res.data.status) {
                    console.log(res.data.data);
                    setReLoadReply(replyId)
                    setUser({
                        fullName: "",
                        email: "",
                        mobile: "",
                        message: "",
                    })
                }
            }).catch((err) => {
                console.log(err.message);
            })

        }
        else {
            setIsLoading(true);
            axios.post(BaseUrl + "userComment", updatedValues).then(
                res => {
                    if (res.data.status) {
                        FetchComment();
                        setUser({
                            fullName: "",
                            email: "",
                            mobile: "",
                            message: "",
                        })
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })



    return (
        <div>
            <NavBar />
            <div className="main-content">
            {isLoad ? (
    <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
        <div className='spinner-grow text-dark'></div>
    </div>
) : (
    <div>
        <div className="about-sect">
            <div className="container">
                <Link to="/sermon" className="text-dark text-medium d-inline-block mb-3">
                    <i className="bi bi-chevron-left"></i> Back
                </Link>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ratio ratio-16x9">
                            <iframe autoplay='true' src={`https://www.youtube.com/embed/${youtube}?si=${youtube}`} title="YouTube video" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="info-section pb-5 mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>{sermon.title}</h2>
                        <p className=" " dangerouslySetInnerHTML={{ __html: sermon.message }}></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}

                {/* {isLoad ?(
                    <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <div className='spinner-grow text-dark '></div>
                    </div> ):(

                <div className="about-sect">
                    <div className="container">
                        <Link to="/Sermon" className="text-dark text-medium d-inline-block mb-3">
                            <i className="bi bi-chevron-left"></i> Back
                        </Link>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ratio ratio-16x9">
                                    <iframe autoplay='true' src={`https://www.youtube.com/embed/${youtube}?si=${youtube}`} title="YouTube video" allowfullscreen></iframe>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>{sermon.title}</h2>
                                <p className=" " dangerouslySetInnerHTML={{ __html: sermon.message }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                )} */}

                <div className="share-bar mb-3">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="text-center"><em>Share sermon via social media</em></div>
                        <ul className="share-buttons list-unstyled d-flex align-items-center" style={{ gap: '15px' }}>

                            <li className="facebook-share">
                                <Link to="https://www.facebook.com/sharer/sharer.html?u=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus&amp;t=HAVE YOU BEEN WITH JESUS?" target="_blank" title="Share on Facebook"><i class="bi bi-facebook"></i>
                                </Link>
                            </li>

                            <li className="twitter-share">
                                <Link to="https://twitter.com/intent/tweet?source=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus&amp;text=HAVE YOU BEEN WITH JESUS?:https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus" target="_blank" title="Tweet">
                                    <i className="bi bi-twitter-x"></i>
                                </Link>
                            </li>

                            <li className="google-share">
                                <Link to="https://plus.google.com/share?url=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus" target="_blank" title="Share on Google+">
                                    <i className="bi bi-google"></i>
                                </Link>
                            </li>

                            <li className="pinterest-share">
                                <Link to="http://pinterest.com/pin/create/button/?url=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus&amp;description=By Pastor Segun Doherty â€œNow when they saw the boldness of Peter and John, and perceived that they were uneducated and untrained men, they marveled. And they realized that they had been with Jesusâ€ - Acts 4:13 It is one thing to say that I am a Christian and it is another thing for your […]" target="_blank" title="Pin it">
                                    <i className="bi bi-pinterest"></i>
                                </Link>
                            </li>

                            <li className="reddit-share">
                                <Link to="http://www.reddit.com/submit?url=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus&amp;title=HAVE YOU BEEN WITH JESUS?" target="_blank" title="Submit to Reddit">
                                    <i className="bi bi-reddit"></i>
                                </Link>
                            </li>

                            <li className="linkedin-share">
                                <Link to="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus&amp;title=HAVE YOU BEEN WITH JESUS?&amp;summary=By Pastor Segun Doherty â€œNow when they saw the boldness of Peter and John, and perceived that they were uneducated and untrained men, they marveled. And they realized that they had been with Jesusâ€ - Acts 4:13 It is one thing to say that I am a Christian and it is another thing for your […]&amp;source=https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus" target="_blank" title="Share on LinkedIn">
                                    <i className="bi bi-linkedin"></i>
                                </Link>
                            </li>

                            <li className="email-share">
                                <Link to="mailto:?subject=HAVE YOU BEEN WITH JESUS?&amp;body=By Pastor Segun Doherty â€œNow when they saw the boldness of Peter and John, and perceived that they were uneducated and untrained men, they marveled. And they realized that they had been with Jesusâ€ - Acts 4:13 It is one thing to say that I am a Christian and it is another thing for your […]:https://rccgvictorycenter.org.uk/?sermons=have-you-been-with-jesus" target="_blank" title="Email">
                                    <i className="bi bi-envelope"></i>
                                </Link>
                            </li>

                        </ul>
                    </div>
                </div>

                <div className="container mb-5">
                    {comment.map((comment) => (
                        <SermonComment {...comment} setReplyId={setReplyId} reLoad={comment._id == reLoadReply} />
                    ))}



                </div>

                <div className="info-section pb-5" id="post">
                    <div className="container">
                        <h5 className="text-center">Post a comment</h5>
                        <div className="row">
                            <div className="col-lg-12">
                                <p>
                                    <b className="text-danger">{Error}</b>
                                </p>
                                <div
                                    className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                        } d-${result.message ? 'block' : 'none'}`}>
                                    {result.message}
                                </div>
                                <form method="post" action="" onSubmit={HandleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" required onChange={inputChange} value={user.fullName} placeholder="Full name" name="fullName" />
                                                <label>Full name</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-floating mb-3">
                                                <input type="email" className="form-control" required onChange={inputChange} value={user.email} placeholder="Email address" name="email" />
                                                <label>Email address</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-floating mb-3">
                                                <input type="tel" className="form-control" required onChange={inputChange} value={user.mobile} placeholder="Phone number" name="mobile" />
                                                <label>Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <textarea className="form-control" name='message' required onChange={inputChange} value={user.message} style={{ height: '100px' }} placeholder="Message"></textarea>
                                                <label>Message</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center mt-4">
                                                <button disabled={isLoading} type="submit" name='submit' className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                    {isLoading ? "Loading..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>
    )
}

export default SermonDetails