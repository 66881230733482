import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'

const UserNav = () => {
    const navigate = useNavigate();

    const LogOut = () => {
        localStorage.removeItem("token");
        navigate("/Login", { replace: true })
    }
    const LogO = () => {
        navigate("/donation")
    }
    return (
        <div>
            <div className="top-bar bg-color-2 py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-unstyled d-flex align-items-center justify-content-end mb-0" style={{ gap: "10px;" }}>
                                <li><p style={{ cursor: 'pointer' }} onClick={LogOut} className="text-white text-decoration-none mt-3 fs-6">Log out</p></li>
                                <li><span className="text-white">|</span></li>
                                <li><p style={{ cursor: 'pointer' }} onClick={LogO} className="text-white text-decoration-none mt-3  fs-6">Donate</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white sticky-top">
                <div className="container">
                    <a className="navbar-brand" >
                        <img src={logo} alt='jf' className="img-fluid" style={{ height: "70px;" }} />
                    </a>
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav main-nav-item ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Add-Prayer-Request">Add Prayer Request</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Add-Testimonial">Add Testimonial</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Members">Members</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/user-consultation">Consultation</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/notification">Notification</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Account Settings
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="nav-link" aria-current="page" to="/transaction">Transaction</Link>
                                        <Link className="nav-link" aria-current="page" to="/Dashboard">Profile</Link>
                                        <Link className="nav-link" aria-current="page" to="/user-change-password">Change Password</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default UserNav