import { configureStore } from "@reduxjs/toolkit";
import { loginReducer } from "./Slices/UserSlices";
import { adminReducer } from "./Slices/adminSlice";
import { pastorReducer } from "./Slices/PastorSlice";
import { superReducer } from "./Slices/SuperSlice";


const store = configureStore({
    reducer :{
        login: loginReducer,
        admin: adminReducer,
        pastor: pastorReducer,
        super: superReducer
    }
})

export default store