import React, { useEffect, useRef, useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import ava from '../images/000.png'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import moment from 'moment'
import AdminNav from './AdminNav'
import ReactQuill from 'react-quill'
import Footer from '../LandingPage/Footer'
import {  useNavigate } from 'react-router'



const Events = () => {

    const [file, setFile] = useState();
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const title = useRef();
    const time = useRef();
    const date = useRef();
    const message = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const userImageUrl = useRef()
    const [editID, setEditID] = useState('')
    const [event, setEvent] = useState([])
    const [eventUrl, setEventUrl] = useState('');
    useEffect(() => {
        FetchData();
    }, [])
    const FetchData = () => {
        axios.get(BaseUrl + 'getEvent')
            .then(data => {
                setEvent(data.data.data);
                setEventUrl(data.data.EventLink);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSubmit = () => {
        const data = new FormData();
        data.append("title", title.current.value)
        data.append("date", date.current.value)
        data.append("time", time.current.value)
        data.append("message", message.current.getEditor().root.innerHTML)
        data.append("userImageUrl", userImageUrl.current.files[0])
        const joinDate = moment().format('YYYY-MM-DD');
        data.append("joinDate", joinDate);
        setIsLoading(true);

        if (editID) {
            axios.patch(BaseUrl + `EditEvents/${editID}`, data).then((res) => {
                if (res.data.status) {
                    FetchData();
                    setUser({
                        title: "",
                        time: "",
                        date: "",
                        message: "",
                    });
                    userImageUrl.current.value = '';
                    setFile([]);
                    setEditID('')
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            }).catch((err) => {
                console.log(err.message);
            })
                .finally(() => {
                    setIsLoading(false);
                });

        }
        else {

            setIsLoading(true);
            axios.post(BaseUrl + "adminEvents", data).then(
                res => {
                    if (res.data.status) {
                        FetchData();
                        title.current.value = '';
                        time.current.value = '';
                        date.current.value = '';
                        message.current.getEditor().root.innerHTML = '';
                        userImageUrl.current.value = '';
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const DeleteEvents = (_id) => {
        axios.delete(BaseUrl + `delEvents/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();
                } else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
    };

    const [user, setUser] = useState([])


    const inputIsChanging = (e) => {
        const name = e.target.name
        const value = e.target.value
        // console.log(value)
        setUser({ ...user, [name]: value })
    }
    const EditEvents = (_id) => {

        // setEdit(true);
        setEditID(_id)
        setUser(() => event.find((val) => val._id == _id))

    }

    const navigate = useNavigate();
    // const viewAttendance = (_id)=>{
    //     navigate("/admin/event-attendance/?userId="+_id)
    // }
    const viewAttendance = (_id) => {
        navigate("/admin/event-attendance/?userId=" + _id);
    };


    return (
        <div>
            <AdminNav />

            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Events</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className="row mt-5">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input name='title' type='title' className="form-control" ref={title} onChange={inputIsChanging} value={user?.title} placeholder="Events Time" />
                                            <label>Title</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input name='time' type='time' className="form-control" ref={time} onChange={inputIsChanging} value={user?.time} placeholder="Events Time" />
                                            <label>Time</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input name='date' type='date' className="form-control" ref={date} onChange={inputIsChanging} value={user?.date} placeholder="Events Date" />
                                            <label>Date</label>
                                        </div>
                                        <div className="form-floating mb-5">
                                            <ReactQuill
                                                theme="snow"
                                                ref={message}
                                                value={user?.message || ''}
                                                onChange={(value) => inputIsChanging({ target: { name: 'message', value } })}
                                                style={{ height: '300px' }}
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['link', 'image'],
                                                        ['clean']
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <div className="form-floating mb-3">
                                            <div class="form-group row mt-4">
                                                <label class="col-md-2 fw-bold">Add Image:</label>
                                                <div class="col-md-3 show-image">
                                                    <img src={user?.userImageUrl ? user?.userImageUrl : file ? file : ava} alt='eee' height="200" width="150" />
                                                    <em class="text-center ">Show Image Upload</em>
                                                </div>
                                                <div class=" form-group col-md-3   ml-2">
                                                    <label for="file-upload" class="custom-file-upload btn btn">
                                                        <input id="file" type="file" ref={userImageUrl} onChange={handleChange} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" onClick={handleSubmit} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                                <b>
                                                    {isLoading ? "Loading..." : "Submit"}
                                                </b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div style={{ height: '300px' }} className="overflow-auto bg-light p-lg-4 p-3 rounded">
                                    <h5 className="text-center mb-3">Events</h5>


                                    <hr />
                                    {event.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post) => (
                                        <div className='container mb-3 border-bottom'>
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <img src={post.userImageUrl} alt='kdf' className='img-fluid' />

                                                </div>
                                                <div className='col-lg-7'>
                                                    <h5>Theme :{post.title}</h5>
                                                    <p className="line-clamp-3 ft-lg " dangerouslySetInnerHTML={{ __html: post.message }}></p>
                                                    <h6>Time : {post.time}</h6>
                                                    <h6>Date : {post.date}</h6>
                                                    <p className="text-muted text-medium ft-sm"><em>Published on {post.joinDate}.</em></p>
                                                    <button type="submit" name="submit" onClick={() => DeleteEvents(post._id)} className="btn btn text-danger" >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                    <button type="submit" name="submit" onClick={() => EditEvents(post._id)} className="btn btn text-success  mr-2" >
                                                        <i className='fa fa-edit'></i>
                                                    </button>
                                                    <button type="submit" name="submit" onClick={() => viewAttendance(post._id)} className="btn btn text-success  mr-2" >
                                                        <i className='fa fa-eye'></i>
                                                    </button>
                                                    {eventUrl && (
                                                        <div>
                                                            <p>Share the link with the members to register for the event</p>
                                                            <a href={`${eventUrl}event-register?id=${post._id}`}>{`${eventUrl}event-register?id=${post._id}`}</a>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Events;