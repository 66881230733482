import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import NavBar from './NavBar'
import Banner from '../images/banner.jpeg'
import worshipp from '../images/worship-1.jpg'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import moment from 'moment'
import { parseISO, formatDistanceToNow } from 'date-fns';
import Footer from './Footer'


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}

const Event = () => {
    const navigate = useNavigate()
    const [news, setNews] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [eventUrl, setEventUrl] = useState('');


    useEffect(() => {
        FetchData();
    }, [])
    const FetchData = () => {
        setIsLoading(true)
        axios.get(BaseUrl + 'getEvent')
            .then(data => {
                setNews(data.data.data?.sort((a, b) => b.createdAt.localeCompare(a.createdAt)));
                setEventUrl(data.data.EventLink);

            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    const handlePost = (_id) => {
        navigate("/event-details/?userId=" + _id)
    }
    return (
        <div>
            <NavBar />

            <div className="main-content">

                {isLoading ?
                    <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <div className='spinner-grow text-dark '></div>
                    </div> :


                    <div className="blog-sect">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p onClick={() => handlePost(news && news[0]._id)} className="text-decoration-none text-dark">
                                        <div className="card bg-light border-0 rounded-0 mb-3">
                                            <div className="row align-items-center g-0">
                                                <div className="col-md-6">
                                                    <div className="single-main-news">
                                                        <img src={news && news[0]?.userImageUrl} alt='ghj' className="img-fluid object-fit-cover object-position-center w-100 h-100" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card-body p-4">
                                                        <p className="text-danger text-bold text-uppercase">News</p>
                                                        <h1 className="card-title big-text">{news && news[0]?.title}</h1>
                                                        <p className="card-text line-clamp-3 ft-lg" dangerouslySetInnerHTML={{ __html: news && news[0]?.message }}></p>
                                                        {/* <p className="card-text line-clamp-3 ft-lg">{news&&news[0]?.message}</p> */}
                                                        {/* dangerouslySetInnerHTML={{ __html: post.message }} */}
                                                        <p className="card-text"><small className="text-body-secondary">Published on {TimeAgo(news && news[0]?.createdAt)}</small></p>
                                                        {/* <a href={`${eventUrl}event-register?id=${news._id}`}>{`${eventUrl}event-register?id=${news._id}`}</a> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>

                                <div className="col-lg-9 my-4">
                                    <h4 className="text-medium">Most Popular</h4>
                                    <hr />
                                    <div className="row">
                                        {news && news?.map((post) => (
                                            <div className="col-lg-4 mb-3">
                                                <p onClick={() => handlePost(post._id)} className="text-decoration-none text-dark p-3 d-block border-radius-15 bg-light">
                                                    <div className="position-relative mb-3">

                                                        <div className="img-box box-height-2 border-radius-15">
                                                            <img src={post.userImageUrl || worshipp} alt='sjkd' className="img-fluid object-fit-cover object-position-center w-100 h-100" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h4>{post.title}</h4>

                                                        {/* <p className="text-muted text-medium ft-sm mb-0"><em>Published on {moment(post.joinDate).format('Do MMM, YYYY')} | Admin</em></p> */}
                                                        <p className="text-muted text-medium ft-sm mb-0"><em>Published on {TimeAgo(post.createdAt)} | Admin</em></p>
                                                    </div>
                                                </p>

                                            </div>
                                        ))}
                                    </div>
                                </div>


                                <div className="col-lg-3 my-lg-4">
                                    <h4 className="text-medium">Latest news</h4>
                                    <hr />
                                    <div className="mb-3">
                                        {news && news.map((post, _id) => (
                                            <p onClick={() => handlePost(post._id)} className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                                <div className="position-relative flex-shrink-0 me-3">
                                                    <div className="img-box box-height-3 border-radius-15">
                                                        <img src={post.userImageUrl || worshipp} alt='shdj' className="img-fluid object-fit-cover object-position-center w-100 h-100" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="text-uppercase ft-sm sermon-text text-danger">EVENTS</span>
                                                    <h6>{post.title}</h6>

                                                    <p className="text-muted text-medium ft-sm mb-0"><em>Published on {moment(post.joinDate).format('Do MMM, YYYY')} | Admin</em></p>
                                                </div>
                                            </p>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                }

            </div>

            <Footer />


        </div>
    )
}

export default Event