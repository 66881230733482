import React,{useState,useEffect} from 'react'
import avatar from '../images/avatar-1.jpeg'
import BaseUrl from '../BaseUrl'
import axios from 'axios'

const SermonComment = (comment) => {
    const [Reply, setReply] = useState([])
    useEffect(() => {
        FetchReply();
    }, [comment.reLoad])

    const FetchReply = () => {
        axios.get(BaseUrl + `getUserReply/${comment._id}`)
            .then(res => {
                if(res.data.status){
                    console.log(res.data.data);
                    setReply(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const ReplyComment=(id)=>{
        comment.setReplyId(id)
    }
  return (
    <div className="card mt-2 border-3 pt-2 active pb-0 px-3">
    <div className="ms-lg-4 mb-3">
        <div className="card mt-2 border-3 pt-2 active pb-0 px-3">
            <div className="card-body ">
                <div className="row">
                    <div className="col-12 ">
                        <div className="d-flex ">
                            <div className="avatar-circle-2 flex-shrink-0 me-3">
                                <img src={avatar} alt="dfj" className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                            </div>
                            <div>
                                <h6 className="card-title "><b>{comment.fullName} says</b></h6>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    <p className="card-text text-muted small ">
                                        <img src="https://img.icons8.com/color/26/000000/christmas-star.png" className="mr-1 "
                                            width="19" alt='snks' height="19" id="star" /> <span className="vl mr-2 ml-0"></span>

                                        <i className="fa fa-users text-muted "></i> Public <span className="vl ml-1 mr-2 "></span>

                                        Created on 1 {comment.joinDate}
                                    </p>
                                </h6>
                                <p className='mt-3'>{comment.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-white px-0 ">
                <div className="row">

                    <div className="col-md-auto ">
                        <ul className="list-inline d-flex align-items-center">
                            <li className="list-inline-item">
                                <a href="#post" onClick={() => ReplyComment(comment._id)} className="text-decoration-none">
                                    <div className="d-flex align-items-center">
                                        <img src={avatar} alt='kjdbjh' width="30" height="30 " className="more me-2" />
                                        <span className="text-dark" >Reply</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>  


    {/*  */}
{Reply.map((respond) => (

    <div className="ms-lg-4 mb-3">
    <div className="card mt-2 border-3 pt-2 active pb-0 px-3">
        <div className="card-body ">
            <div className="row">
                <div className="col-12 ">
                    <div class="d-flex ">
                        <div className="avatar-circle-2 flex-shrink-0 me-3">
                            <img src="images/avatar-1.jpeg" alt='skj' className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                        </div>
                        <div>
                            <h6 class="card-title "><b>{respond.fullName}</b></h6>
                            <h6 class="card-subtitle mb-2 text-muted">
                                <p class="card-text text-muted small ">
                                    <img src={avatar} alt='jdkf' className="mr-1 "
                                        width="19" height="19" id="star" /> <span class="vl mr-2 ml-0"></span>

                                    <i className="fa fa-users text-muted "></i> Public <span className="vl ml-1 mr-2 "></span>

                                    {respond.joinDate}
                                </p>
                            </h6>
                            <p> {respond.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
))}
{/*  */}


        </div>
    </div>
</div>
  )
}

export default SermonComment