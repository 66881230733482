import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import avatarMan from '../images/000.png'
import avatarWoman from '../images/0111.png'
import Worshipp from '../images/worship-2.jpg'
import AdminNav from './AdminNav'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { parseISO, formatDistanceToNow } from 'date-fns';


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}



const AddAdminMember = () => {

    useEffect(() => {
        getAdminMember();
        // FetchData();
        fetchMember();
    }, [])



    // getting the member added to the department 
    const [section, setSection] = useState([])

    const getAdminMember = async () => {
        await axios.get(BaseUrl + 'getAddAdminMember')
            .then(data => {
                setSection(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    //   getting all department in drop down
    // const [depart, setDepart] = useState()
    // const FetchData = () => {
    //     axios.get(BaseUrl + "getAllAdmin")
    //         .then(data => {
    //             const info = data.data.data
    //             setDepart(info);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }
    // fetching all member
    const [members, setMembers] = useState([])
    const fetchMember = async () => {
        await axios.get(BaseUrl + 'getAllUser')
            .then(data => {
                setMembers(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    };
    // deleting department
    const DeleteAdmin = (_id) => {

        axios.patch(BaseUrl + `deleteAddAdmin/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    getAdminMember();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    //   editing department
    const [user, setUser] = useState([])
    const [editID, setEditID] = useState('')

    const EditAdmin = (_id) => {
        setEditID(_id)
        setUser(() => section.find((val) => val._id == _id))
    }

    // search for member 
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredMembers = members.filter((member) =>
        member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const [searchMember, setSearchMember] = useState('');
    const handleMember = (e) => {
        setSearchMember(e.target.value);
    };
    const filteredDepartment = section.filter((member) =>
        member.fullName.toLowerCase().includes(searchMember.toLowerCase()) ||
        member.department.toLowerCase().includes(searchMember.toLowerCase())
    )

    // handleAdmin adding the deparment
    const [Error, setError] = useState("")

    const [result, setResult] = useState({
        message: "",
        status: false
    })
    // const searchBox = useRef()
    // const [user, setUser] = useState(false)
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }
    const handleAdmin = (_id) => {

        if (editID) {
            axios.patch(BaseUrl + `editAddAdminMember/${editID}`, user)
                .then((res) => {
                    if (res.data.status) {
                        getAdminMember();
                        setUser({
                            admin: "",
                        });
                        setEditID('')

                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch((err) => {
                    console.log(err.message);
                })

        } else {

            axios.patch(BaseUrl + `addAdminMember/${_id}`, user)
                .then((res) => {
                    if (res.data.status) {
                        getAdminMember();
                        setUser({
                            admin: "",
                        });
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }





    return (
        <div>
            <AdminNav />

            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Add Member To Department</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <form method="post" action="">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <select
                                                    name='admin'
                                                    onChange={handleChange}
                                                    required
                                                    value={user?.admin}
                                                    className="form-control my-2" >
                                                    <option selected>--Select--</option>
                                                    <option value="Super Admin">Super Admin</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="Pastor">Pastor</option>
                                                </select>
                                                <label >Admin</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <input type='text' name='search' placeholder='Search for Member' value={searchTerm || user?.fullName} onChange={handleSearch} className='centered-placeholder form-control' />
                                                <label>Search For Member</label>
                                            </div>
                                        </div>

                                    </div>
                                </form>


                                <div className="info-section pb-5 mt-5">
                                    <div className="row ">
                                        {filteredMembers.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post) => (
                                            <div className="col-lg-6">
                                                <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                                    <div className="position-relative">

                                                        <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                            <img 
                                                            src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}
                                                            alt='sdbjh' 
                                                            className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                                        </div>

                                                    </div>
                                                    <div>

                                                        <p className="mb-2">{post.fullName}</p>
                                                        <p style={{ cursor: 'pointer' }} type="submit" name="submit" onClick={() => handleAdmin(post._id)} className="btn btn text-medium ft-sm text-white bg-success">
                                                                <i className="fa fa-plus" ></i>
                                                            </p>
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* </div> */}
                                </div>

                            </div>

                            <div className="col-lg-6">
                                <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for Member' value={searchMember} onChange={handleMember} className='w-100 mx-auto centered-placeholder form-control m-2' /><br />
                                <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S/N</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Admin</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredDepartment.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{post.fullName}</td>
                                                    <td>{post.admin}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            onClick={() => EditAdmin(post._id)}
                                                            className="btn btn text-success mr-1"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => DeleteAdmin(post._id)}
                                                            className="btn btn  text-danger ml-1"
                                                        >
                                                            <b><i className="fa fa-trash"></i></b>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <footer className="footer text-white py-5" style={{ backgroundColor: "#222123" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                            <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                            <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                            <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                            <ul className="list-unstyled">
                                <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                                <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                            <p className="mb-0">P.O. Box 18160</p>
                            <p>Richmond, VA 23226</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                                <ul className="list-unstyled d-flex mb-0" style={{ gap: "10px" }}>
                                    <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div >
    )
}

export default AddAdminMember