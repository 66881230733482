import React, { useEffect, useState } from 'react'
import AdminNav from './AdminNav'
import Worshipp from '../images/worship-2.jpg'
import { Link } from 'react-router-dom'
import avatarMan from '../images/000.png'
import avatarWoman from '../images/0111.png'
import BaseUrl from '../BaseUrl'
import axios from 'axios'



const AllPrayer = () => {

    const [prayer, setPrayer] = useState([])
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        axios.get(BaseUrl + "getAllPray")
            .then(data => {
                setPrayer(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    const filteredMembers = prayer.filter((member) =>
        member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const [Error, setError] = useState("");

    const DeletePray = (_id) => {
        axios.delete(BaseUrl + `delPray/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();

                } else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })

    };

    const approvePray=(_id)=>{
        axios.post(BaseUrl + `approvePray/${_id}`)
        .then((res)=>{
            if(res.data.status){
                FetchData();
            }
            else {
                setError(res.data.message);
            }
            setResult({
                message: res.data.message,
                status: res.data.status,
            });
            setTimeout(() => {
                setResult({
                    message: "",
                    status: false,
                });
                setError("");
            }, 3000);
        })
        .catch((err) => {
            console.log(err.message);
        })
    }





    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Prayer Requests</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="alert border-0 alert-warning alert-dismissible fade show" role="alert">
                            <h5 className="text-center ">Our prayer team will love to pray with you</h5>
                        </div>
                        <input type='text' name='search' placeholder='Search for a member' value={searchTerm} onChange={handleSearch} className='w-25 form-control mb-4' />

                        <div className="row ">
                            <p>
                                <b className="text-danger">{Error}</b>
                            </p>
                            <div
                                className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                    } d-${result.message ? 'block' : 'none'}`}>
                                {result.message}
                            </div>

                            {filteredMembers.map((post) => (
                                <div className="col-lg-4">
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <div className="position-relative">

                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                <img 
                                                src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}
                                                alt='sdbjh' 
                                                className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                            </div>

                                        </div>
                                        <div>

                                            <p className="mb-0">{post.fullName}</p>
                                            <p className="text-muted text-medium ft-lg mb-0 font-weight-bold">Prayer Point: {post.message}</p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>{post.joinDate}</em></p>

                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                <Link to={post.mobile} className="text-decoration-none text-dark">
                                                    <i className="bi bi-phone"></i> Call
                                                </Link>
                                                <Link to={post.email} className="text-decoration-none text-dark">
                                                    <i className="bi bi-envelope"></i> Send a message
                                                </Link>
                                            </div>

                                            <div className="d-flex mt-2" style={{ gap: '20px' }}>
                                                <button type="submit" name="submit" onClick={() => approvePray(post._id)} className="btn btn bg-success text-light" >
                                                    <b>
                                                         <i className="fa fa-check"></i>
                                                    </b>
                                                </button>
                                                <button type="submit" name="submit" onClick={() => DeletePray(post._id)} className="btn btn bg-danger text-light" >
                                                    <b>
                                                        <i className="fa fa-trash"></i>
                                                    </b>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <footer className="footer text-white py-5" style={{ backgroundColor: '#222123' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                            <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                            <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                            <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                            <ul className="list-unstyled">
                                <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                                <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                            <p className="mb-0">P.O. Box 18160</p>
                            <p>Richmond, VA 23226</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                                <ul className="list-unstyled d-flex mb-0" style={{ gap: '10px' }}>
                                    <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default AllPrayer