import React, {  useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import { Link } from 'react-router-dom'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import NavBar from './NavBar'
import moment from 'moment'



const GuestPrayer = () => {

    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const [user, setUser] = useState({
        fullName: "",
        email: "",
        mobile: "",
        message: "",
    })

    const inputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }
        
    const HandleSubmit = (e) => {
        e.preventDefault(e);
        const joinDate = moment().format('YYYY-MM-DD');
        const updatedValues = { ...user,  joinDate:joinDate };
        setIsLoading(true);
        axios.post(BaseUrl + "userPray", updatedValues).then(
            res => {
                if (res.data.status) {
                    setUser({
                        fullName: "",
                        email: "",
                        mobile: "",
                        message: "",
                    });
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })
    return (
        <div>
            <NavBar />

            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Prayer Request</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-section pb-5">
                    <div className="container">
                        <h4 className="text-center mb-5"> Our prayer team will love to pray with you</h4>
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <form method="post" action="" onSubmit={HandleSubmit}>
                        <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" required placeholder="Full name" value={user.fullName} onChange={inputChange} name='fullName' />
                                            <label>Full name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" required placeholder="Email address" value={user.email} onChange={inputChange} name='email' />
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="tel" className="form-control" required placeholder="Phone number" value={user.mobile} onChange={inputChange} name='mobile' />
                                            <label>Phone Number</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <textarea className="form-control" required style={{ height: '100px' }} placeholder="Message" value={user.message} onChange={inputChange} name='message'></textarea>
                                            <label>Message</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button disabled={isLoading} type="submit" name='submit'  className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                {isLoading ? "Loading..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </form>

                    </div>
                </div>
            </div>

            <footer className="footer text-white py-5" style={{ backgroundColor: "#222123" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                            <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                            <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                            <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                            <ul className="list-unstyled">
                                <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                                <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                            <p className="mb-0">P.O. Box 18160</p>
                            <p>Richmond, VA 23226</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                                <ul className="list-unstyled d-flex mb-0" style={{ gap: "10px" }}>
                                    <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <button onclick="topFunction()" id="myBtn" title="Go to top">Top</button>

        </div>
    )
}

export default GuestPrayer