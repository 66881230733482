import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import { Link, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import Footer from './Footer'


const NewsDetails = () => {

    const [news, setNews] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        setIsLoading(true);

        axios.get(BaseUrl + `moreNew/${searchParams.get('userId')}`)
            .then(res => {
                if (res.data.status) {
                    setNews(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    return (
        <div>
            <NavBar />


                {isLoading ?  <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <div className='spinner-grow text-dark '></div>
                        
                    </div>:
            <div className="main-content">
                
                <div className="about-sect">
                    <div className="container">
                        <Link to="/news" class="text-dark text-medium d-inline-block mb-3">
                            <i className="bi bi-chevron-left"></i> Back
                        </Link>
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <img src={news.userImageUrl} alt='fkjk' className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <h5>{news.title}</h5>
                                <p className=" " dangerouslySetInnerHTML={{ __html: news.message }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                        </div>

}



            <Footer />

        </div>
    )
}

export default NewsDetails