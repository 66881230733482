import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./Form";

const stripePromise = loadStripe("pk_live_51QA7fV07GGtvZOsjnCc3uiNHL2lRPK89QpBhkIIdPxXMqa3HYIPlia0HzUboq021KhnztaGHqGJeuHkYBebaHBXE00xrCbYBrW");

const StripePayment = ({ clientSecret }) => {
  const options = { clientSecret }

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm />
      </Elements>
    </>
  )
}

export default StripePayment;