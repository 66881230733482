import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import Worshipp from '../images/worship-2.jpg';
import { useSelector } from 'react-redux';
import Footer from '../LandingPage/Footer';
import UserNav from './UserNav';


const UserNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const { loginUser: user } = useSelector((state) => state.login)

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(BaseUrl + `usernotification/${user?._id}`)
            if (response.data.status) {
                setNotifications(response.data.data);
            } else {
                console.error("Failed to fetch notifications.");
            }
        } catch (err) {
            console.error("An error occurred while fetching notifications:", err);
        }
    };

    const Delete = (_id) => {
        axios.delete(BaseUrl + `remove/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    fetchNotifications();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const formatTime = (time) => {
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        minutes = minutes.padStart(2, '0');
        return `${hours}:${minutes} ${period}`;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
        };


    return (
        <div>
            <UserNav />
            <div className="">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Consultation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mb-5">
                    <h3 className="text-center mt-4">Notifications</h3>
                    <div className="col-lg-7 mx-auto">
                        <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">Purpose</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notifications.length > 0 ? (
                                        notifications.map((notification, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{capitalizeFirstLetter(notification.purpose)}</td>
                                                <td>{notification.date}</td>
                                                <td>{formatTime(notification.startTime)} - {formatTime(notification.endTime)}</td>
                                                <td>
                                                    <i className='text-danger fa fa-trash ml-3' onClick={() => Delete(notification._id)}></i>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr><td colSpan="7" className="text-center">No notifications available.</td></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UserNotifications;
