import { createSlice } from "@reduxjs/toolkit";

const pastorSlice = createSlice({
    initialState: {
        LoginPastor: {}
    },
    name: "pastor",
    reducers: {
        setPastor:(state,actions)=>{
            state.LoginPastor = actions.payload
        }
    }
})


export const pastorReducer = pastorSlice.reducer;
export const {setPastor } = pastorSlice.actions