import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import AdminNav from './AdminNav'
import Worshipp from '../images/worship-2.jpg'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import ava from '../images/000.png'
import Footer from '../LandingPage/Footer'


const ITEMS_PER_PAGE = 9;

const ViewAllDepartment = () => {

    const [department, setDepartment] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    const filteredMembers = department.filter((member) =>
        member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    )


    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        axios.get(BaseUrl + `moreDepartment/${searchParams.get('userId')}`)
            .then(res => {
                if (res.data.status) {
                    console.log(res.data.data);
                    setDepartment(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const [Error, setError] = useState("");
    const deleteMember = (_id) => {
        axios.patch(BaseUrl + `deleteDepartment/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();

                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    const [currentPage, setCurrentPage] = useState(1);

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return filteredMembers.slice(startIndex, endIndex);
    };

    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Department Members {department.length}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="alert border-0 alert-warning alert-dismissible fade show" role="alert">
                            <h5 className="text-center ">Members in the Department</h5>
                        </div>
                        <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for a member' value={searchTerm} onChange={handleSearch} className='w-75 mx-auto centered-placeholder form-control mb-4' />
                        <Link to="/admin/Admin-Department" className='text-dark'>Go Back</Link>
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>

                        <div className="row ">

                            {/* {getPaginatedData().map((post) => ( */}
                            {getPaginatedData().length > 0 ? (
                                getPaginatedData().map((post) => (
                                    <div className="col-lg-4">
                                        <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                            <div className="position-relative">

                                                <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                    <img src={post.userImageUrl || ava} alt='sdbjh' className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                                </div>

                                            </div>
                                            <div>
                                                <p className="mb-0">Name: {post.fullName}</p>
                                                <p className="text-muted text-medium ft-sm mb-0"><em>Department: {post.department}</em></p>
                                                <p style={{ cursor: 'pointer' }} name="submit" onClick={() => deleteMember(post._id)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-trash " style={{ color: 'red' }}></i> Remove
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                ) : (
                                <p className='h6'>Member is yet to be added to this Department</p>
                            )}
{/* // ))} */}


                        </div>


                        <div className="col-lg-12 mt-3">
                            <nav className='mr-auto'>
                                <ul className="pagination mr-auto">
                                    {[...Array(Math.ceil(filteredMembers.length / ITEMS_PER_PAGE)).keys()].map((num) => (
                                        <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
                                                {num + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>


            </div>

            <Footer/>
        </div>
    )
}

export default ViewAllDepartment