import React, { useRef } from 'react'
// import UserNav from './UserNav'
import Worshipp from '../images/worship-2.jpg'
import avatarMan from '../images/000.png'
import avatarWoman from '../images/0111.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
// import { setuser } from '../Slices/UserSlices'
// import moment from 'moment'
import { parseISO, formatDistanceToNow } from 'date-fns';
import Footer from '../LandingPage/Footer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PastorNav from './PastorNav'
import { setPastor } from '../Slices/PastorSlice'


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}


const PastorProfile = () => {
    const dispatch = useDispatch();
    const { LoginPastor: user } = useSelector((state) => state.pastor)

    const userImageUrl = useRef()


    const handleImage = () => {
        const data = new FormData();
        data.append("userImageUrl", userImageUrl.current.files[0])

        axios.patch(BaseUrl + `userImage/${user._id}`, data)
            .then((res) => {
                if (res.data.status) {
                    axios.get(BaseUrl + "getPastor").then(res => {
                        if (res.data.status) {
                            console.log(res.data.data);
                            dispatch(setPastor(res.data.data));
                        }
                    })
                }
                const result = res.data.message;
                toast.success(result, {
                    autoClose: 3000,
                })
            })
            .catch((err) => {
                const error = err.response?.data?.message || 'An error occurred';
                toast.error(error, {
                    autoClose: 3000,
                });
                console.error(err.message);
            })
    }



    return (
        <div>
            <PastorNav />
            <div className="">
                <div className="welcome-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Welcome, {user?.fullName}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="col-lg-12">
                            <em style={{ float: 'right' }}>Member since: {TimeAgo(user.createdAt)}</em>
                            <div className="mb-4">
                                <label >
                                        <em>Change your photo </em>
                                    </label>
                                    <input type="file" onChange={handleImage} ref={userImageUrl} className="" />
                                    <div className="avatar-circle cursor-pointer rounded-circle mx-auto mb-2">
                                        <img
                                            src={user?.userImageUrl || (user?.gender === 'Male' ? avatarMan : avatarWoman)}
                                            alt='sjnd'
                                            className="img-fluid object-fit-cover object-position-center w-100 h-100" />
                                    </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h3 className="mb-0">Profile</h3>
                                <Link to="/pastor/edit-pastor" className="text-dark">Edit Profile</Link>
                            </div>

                            <form>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Mr" value={user?.titles} disabled />
                                            <label>Title</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Full name" value={user?.fullName} disabled />
                                            <label>Full name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" placeholder="Email address" value={user?.email} disabled />
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Phone number" disabled value={user?.mobile} />
                                            <label>Phone number</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" value={user?.postalCode} className="form-control" placeholder="Postal code" disabled />
                                            <label>Postal code</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" value={user?.address} className="form-control" placeholder="Address" disabled />
                                            <label>Address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Date of Birth</label></p>
                                        <span>{user?.Dob}</span>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Gender</label></p>
                                        <span>{user?.gender}</span>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Status</label></p>
                                        <span className="badge text-bg-success">Member</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

            <ToastContainer />

        </div>
    )
}

export default PastorProfile