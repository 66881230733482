import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Worshipp from '../images/worship-2.jpg'
import AdminNav from './AdminNav'
import moment from 'moment'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { parseISO, formatDistanceToNow } from 'date-fns';
import Footer from '../LandingPage/Footer'


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}
const ITEMS_PER_PAGE = 5;

const AdminDepartment = () => {

    const department = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [depart, setDepart] = useState([])

    useEffect(() => {
        FetchData();
    }, [])
    // fecthing all the department
    const FetchData = () => {
        axios.get(BaseUrl + "getAllDepartment")
            .then(data => {
                const information = data.data.data
                setDepart(information.map((val, index) => { return { ...val, index } }));
            })
            .catch(err => {
                console.log(err);
            })
    }

    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const [searchMember, setSearchMember] = useState('');
    const handleMember = (e) => {
        setSearchMember(e.target.value);
    };
    const filteredDepartment = depart.filter((member) =>
        member.department.toLowerCase().includes(searchMember.toLowerCase())
    )


    const HandleSubmit = (e) => {
        e.preventDefault(e);
        const info = {
            department: department.current.value,
            joinDate: moment().format('YYYY-MM-DD')
        }

        if (editID) {
            axios.patch(BaseUrl + `EditAdminDepartment/${editID}`, info)
                .then((res) => {
                    if (res.data.status) {
                        FetchData();
                        setUser({
                            department: "",
                        });
                        department.current.value ="";
                        setEditID('')

                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch((err) => {
                    console.log(err.message);
                })

        } else {
            // adding department
            setIsLoading(true);
            axios.post(BaseUrl + "addDepartment", info).then
                (res => {
                    if (res.data.status) {
                        FetchData();
                        setUser({
                            department: "",
                        });
                        department.current.value = "";
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                }).catch(err => {
                    console.log(err.message)
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    // editing department
    const [user, setUser] = useState([])
    const [editID, setEditID] = useState('')
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }

    const EditDepartment = (_id) => {
        setEditID(_id)
        setUser(() => depart.find((val) => val._id == _id))
    }

    // deleting department
    const DeleteDepartment = (_id) => {
        axios.delete(BaseUrl + `removeDepartment/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const [currentPage, setCurrentPage] = useState(1);

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return filteredDepartment.slice(startIndex, endIndex);
    };
        const navigate = useNavigate();

    const viewDepartment=(department)=>{
        navigate("/admin/view-department/?userId="+department)
      }



    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Add Department</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                              
                                <form method="post" action="" onSubmit={HandleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                        <input type='text' name='department' ref={department} onChange={handleChange} value={user?.department} required placeholder='Enter Department' className='form-control' />
                                                <label>Add Department</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                            <b>
                                                {isLoading ? "Loading..." : "Submit"}
                                            </b>
                                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-lg-6">
                                <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for Department' value={searchMember} onChange={handleMember} className='w-100 mx-auto centered-placeholder form-control m-2' /><br />
                                <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S/N</th>
                                                <th scope="col">Department</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getPaginatedData().map((post, index) => (
                                                <tr key={index}>
                                                    <td>{post.index + 1}</td>
                                                    <td>{post.department}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            onClick={() => viewDepartment(post.department)}
                                                            className="btn btn  text-success "
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => EditDepartment(post._id)}
                                                            className="btn btn  text-success "
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => DeleteDepartment(post._id)}
                                                            className="btn btn  text-danger "
                                                        >
                                                            <b><i className="fa fa-trash"></i></b>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <nav>
                                        <ul className="pagination">
                                            {[...Array(Math.ceil(filteredDepartment.length / ITEMS_PER_PAGE)).keys()].map((num) => (
                                                <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
                                                        {num + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


<Footer/>
        </div>

    )
}

export default AdminDepartment