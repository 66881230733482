import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import worship from '../images/worship-1.jpg'
import NewMember from '../images/new-members.jpg'
import Worshipp from '../images/worship-2.jpg'
import sermon from '../images/sermon.jpg'
import giving from '../images/giving.jpg'
import inner from '../images/background-inner.jpeg'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import BaseUrl from '../BaseUrl'
import axios from 'axios'



const LandingPage = () => {

    const [prophecy, setProphecy] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [header, setHeader] = useState([])

    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        setIsLoading(true);
        axios.get(BaseUrl + 'getProphecy')
            .then(response => {
                if (response.data && Array.isArray(response.data.data)) {
                    setProphecy(response.data.data);
                } else {
                    console.error('Invalid data format received from server:', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const [images, setImages] = useState([]);

    useEffect(() => {
        FetchHeader();
    }, [])
    const FetchHeader = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(BaseUrl + 'getHeader');
            if (response.data.status) {
                setHeader(response.data.data);
                const image1 = response.data.data[0];
                const image2 = response.data.data[1];
                const image3 = response.data.data[2];
                setImages([image1, image2, image3]);
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            <NavBar />


            <div className="main-content">
                <div className="position-relative">
                    <div id="carouselHero" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner">
                            <div className="hero-indicator carousel-indicators">
                                <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" />
                                <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="1" aria-label="Slide 2" />
                                <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="2" aria-label="Slide 3" />
                            </div>
                            {isLoading ? (
                                <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                                    <div className='spinner-grow text-dark'></div>
                                </div>
                            ) : (
                                header.length > 0 ? (
                                    images.map((image, index) => (
                                        <div className="carousel-item active" key={index}>
                                            <div className="position-relative">
                                                <div className="carousel-overlay-sect">
                                                    <div className="container-fluid z-index-1 position-relative">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-6">
                                                                <p className="text-uppercase mb-1 text-white" style={{ letterSpacing: '0.5rem' }}>You are welcome</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hero-sections hero-view-size text-white z-index-1 bg-main-4 bg-image-hero position-relative" style={{ backgroundImage: `url(${image})`, backgroundColor: '#000', backgroundSize: 'cover' }}>
                                                    <div className="overlay-hero"></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No images uploaded yet.</p>
                                )
                            )}
                        </div>
                        <button className="arrow-move carousel-control-prev" type="button" data-bs-target="#carouselHero" data-bs-slide="prev" style={{ zIndex: '12' }}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="arrow-move carousel-control-next" type="button" data-bs-target="#carouselHero" data-bs-slide="next" style={{ zIndex: '12' }}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>







                <div className="info-section bg-light py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <p className="text-center ft-md">
                                    You will experience a positive increase in wisdom, favour, anointing, financial and material possessions. The Lord will increase His peace, love and joy in your life. Go ahead with this second quarter triumphantly In Jesus' Mighty Name. Shalom!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                {isLoading ? <div className='text-center d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                    <div className='spinner-grow text-dark '></div>

                </div> :
                    <div className="info-section py-5">
                        <div className="container">
                            {prophecy.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post, index) => (
                                <div className="row align-items-center">
                                    <div className="col-lg-6 mb-lg-0 mb-3">
                                        <img src={post.userImageUrl} alt='hello' className="img-fluid border-radius-15" />
                                    </div>
                                    <div className="col-lg-5 offset-lg-1">
                                        <h3>{post.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: post.message }}></p>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                }
                {/*  */}
                <div className="info-section grid-sect py-5">
                    <Link to="/become-a-member" className="text-decoration-none">
                        <div className="grid-item grid-cover" style={{ backgroundImage: `url(${NewMember})` }}>
                            <div className="overlay-hero"></div>
                            <h2 className="text-white z-index-1">New Members</h2>
                        </div>
                    </Link>
                    <Link to="/news" className="text-decoration-none">
                        <div className="grid-item grid-cover" style={{ backgroundImage: `url(${Worshipp})` }}>
                            <div className="overlay-hero"></div>
                            <h2 className="text-white z-index-1">News and Events</h2>
                        </div>
                    </Link>
                    <Link to="/sermon" className="text-decoration-none">
                        <div className="grid-item grid-cover" style={{ backgroundImage: `url(${sermon})` }}>
                            <div className="overlay-hero"></div>
                            <h2 className="text-white z-index-1">Sermons</h2>
                        </div>
                    </Link>
                    <Link to="/giving" className="text-decoration-none">
                        <div className="grid-item grid-cover" style={{ backgroundImage: `url(${giving})` }}>
                            <div className="overlay-hero"></div>
                            <h2 className="text-white z-index-1">Donation</h2>
                        </div>
                    </Link>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <h4 className="text-center">
                                    Victory Centre For all Nations was inaugurated in 26th November 2000 and we are a loving and caring Church.
                                </h4>
                                <p className="text-center">
                                    Our vision is in line with RCCG worldwide vision and it’s a place where the word of God is preached undiluted. A miracle centre where victims in any areas of life becomes VICTORS.
                                </p>

                                <p className="text-center">The Lord has been manifesting His presence with miracles, signs and wonders. Surely this is a place to belong. The Lord has been enlarging our tent with parishes in Victory Centre Oxford, Victory Centre Bedford, Victory Centre Walsall, Victory Centre Lincon, Winners’ World Birmingham.
                                </p>

                                <div className="text-center mt-4">
                                    <Link to="/about" className="btn btn-main-1 text-medium rounded-0 py-3 px-4">Learn more about us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section position-relative py-5" style={{ backgroundImage: `url(${inner})` }}>
                    <div className="container z-index-1">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <h2 className="text-color-1 text-center mb-4">
                                    Latest Sermon By Pastor Segun Doherty
                                </h2>

                                <div className="position-relative">
                                    <span className="text-uppercase sermon-text position-absolute">SERMON</span>
                                    <div className="img-box border-radius-15">
                                        <img src={worship} alt='wow' className="img-fluid object-fit-cover object-position-center w-100 h-100" />
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <Link to="/sermon" className="btn btn-light text-medium rounded-0 py-3 px-4">Discover Sermon Library</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-main-2 info-section py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 mx-auto">
                                <h1 className="text-bold text-white text-big text-center mb-4">How can we pray for you?</h1>

                                <div className="text-center mt-4">
                                    <Link to="/Guest-Add-Prayer" className="btn btn-light text-medium rounded-0 py-3 px-4">Submit your prayer request</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}

export default LandingPage