import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Worshipp from '../images/worship-2.jpg';
import BaseUrl from '../BaseUrl';
import Footer from '../LandingPage/Footer';
import { useSelector } from 'react-redux';
import SuperNav from './SuperNav'


const SuperAdminConsultation = () => {;
    const { LoginSuper: user } = useSelector((state) => state.super)
    const [consultations, setConsultations] = useState([]);
    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [purpose, setPurpose] = useState('');

    useEffect(() => {
        fetchConsultations();
    }, []);

    const fetchConsultations = async () => {
        try {
            const response = await axios.get(BaseUrl + `getAdminConsultation/${user?._id}`)
            if (response.data.status) {
                setConsultations(response.data.data);
            } else {
                console.error("Failed to fetch consultation times.");
            }
        } catch (err) {
            console.error("An error occurred while fetching consultation times:", err);
        }
    };

    const handleAddConsultation = async () => {
        const userId = user?._id;
        const fullName = user?.fullName;
        if (purpose && date && startTime && endTime) {
            const newConsult = { purpose, date, startTime, endTime };
            const newConsultation = { ...newConsult, fullName, userId };
            console.log(newConsultation);

            try {
                const response = await axios.post(`${BaseUrl}adminconsultations`, newConsultation);
                if (response.data.status) {
                    setConsultations([...consultations, newConsultation]);
                    setPurpose('');
                    setDate('');
                    setStartTime('');
                    setEndTime('');
                } else {
                    console.error("Failed to add consultation time.");
                }
            } catch (err) {
                console.error("An error occurred while adding the consultation time:", err);
            }
        }
    };

    const formatTime = (time) => {
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        minutes = minutes.padStart(2, '0');
        return `${hours}:${minutes} ${period}`;
    };

    const Delete = (_id) => {
        axios.delete(BaseUrl + `removeConsult/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    fetchConsultations();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    return (
        <div>
            <SuperNav />
            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Consultation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-6 mx-auto mb-lg-0 mb-4">
                            <h3 className="text-center mt-4">Set Consultation Time</h3>
                            <div className="form-group">
                                <label>Purpose</label>
                                <select
                                    id="consultationPurpose"
                                    onChange={(e) => setPurpose(e.target.value)}
                                    value={purpose}
                                    className="form-control" >
                                    <option selected>--Select--</option>
                                    <option value="marriage">Marriage</option>
                                    <option value="education">Education</option>
                                    <option value="finance">Finance</option>


                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="consultationDate">Date</label>
                                <input
                                    type="date"
                                    id="consultationDate"
                                    className="form-control"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="consultationStartTime">Start Time</label>
                                <input
                                    type="time"
                                    id="consultationStartTime"
                                    className="form-control"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="consultationEndTime">End Time</label>
                                <input
                                    type="time"
                                    id="consultationEndTime"
                                    className="form-control"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </div>
                            <button className="btn btn-primary mt-3" onClick={handleAddConsultation}>
                                Add Consultation Time
                            </button>
                        </div>

                        <div className="col-lg-6 ">
                            <div className='bg-light p-lg-4 p-5 h-75 rounded'>
                                <h4 className="text-center mt-2 mb-5">Scheduled Consultations</h4>
                                <ul className="list-group">
                                    {consultations.length > 0 ? (
                                        consultations.map((consultation, index) => (
                                            <li key={index} className="list-group-item">
                                                {consultation.fullName} will be available for {consultation.purpose} on {consultation.date}, from {formatTime(consultation.startTime)} to {formatTime(consultation.endTime)}
                                                <i className='text-danger fa fa-trash ml-3' onClick={() => Delete(consultation._id)}></i>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="list-group-item">No consultations scheduled.</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default SuperAdminConsultation
