import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import NavBar from './NavBar'
import { Link, useNavigate } from 'react-router-dom'
import worshipp from '../images/worship-2.jpg'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"
import moment from 'moment'



const Sermon = () => {
    const navigate = useNavigate();
    const [sermon, setSermon] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        FetchData();
      }, [])
  
      const FetchData =()=>{
          axios.get(BaseUrl + 'getSermon')
                          .then(data => {
                              setSermon(data.data.data.sort((a,b)=>b.createdAt.localeCompare(a.createdAt)));
                              setIsLoading(false);
                          })
                          .catch(err => {
                              console.log(err);
                              setIsLoading(false);
                          })
                        }

            const handleSermon=(_id)=>{
                  navigate("/Sermon-Details/?userId="+_id)
            }            
  return (
    <div>
        <NavBar/>

        <div className="main-content">
        <div className="about-sect">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid-item inner-box grid-cover mb-5" style={{backgroundImage: `url(${worshipp})`}}>
                            <div className="overlay-hero"></div>
                            <h2 className="text-white z-index-1">Sermon</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="info-section pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <div>
                                    {sermon && sermon.length === 0 ? (
                                        <div>
                                            <p>Sorry, No Sermon Available at the Moment</p>
                                            <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="text-danger" />
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {sermon && sermon.map((post) => (
                                                <div key={post._id} className="col-lg-4 mb-3">
                                                    <p onClick={() => handleSermon(post._id)} className="text-decoration-none text-dark p-3 d-block border-radius-15 bg-light">
                                                        <div className="position-relative mb-3">
                                                        </div>
                                                        <div>
                                                            <h4>{post.title}</h4>
                                                            <p className="text-muted text-medium ft-sm mb-0"><em>Published on {moment(post.joinDate).format('Do MMM, YYYY')} | Admin</em></p>
                                                        </div>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

    </div>

    
    <footer className="footer text-white py-5" style={{backgroundColor: '#222123'}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                <ul className="list-unstyled">
                    <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                    <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                </ul>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                <p className="mb-0">P.O. Box 18160</p>
                <p>Richmond, VA 23226</p>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col-lg-12">
                <div className="d-flex justify-content-between align-items-center">
                    <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                    <ul className="list-unstyled d-flex mb-0" style={{gap: '10px'}}>
                        <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                        <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                        <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<button onclick="topFunction()" id="myBtn" title="Go to top">Top</button>


    </div>
  )
}

export default Sermon