import React, { useEffect, useState } from 'react'
import avatarMan from '../images/000.png'
import Worshipp from '../images/worship-2.jpg'
import avatarWoman from '../images/0111.png'
// import { Link } from 'react-router-dom'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import PastorNav from './PastorNav'
import Footer from '../LandingPage/Footer'

const PastorBirthday = () => {

    const [birthday, setBirthday] = useState([])
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {

        fetchData();

    }, []);

    const fetchData = async () => {
        await axios.get(BaseUrl + 'getBirthday')
            .then(res => {
                if (res.data.status) {
                    const currentMonth = new Date().getMonth();
                    const filteredData = res.data.data.filter(item => {
                        const itemMonth = new Date(item.Dob).getMonth();
                        return itemMonth === currentMonth;
                    });
                    setBirthday(filteredData);
                    // console.log(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    const filteredMembers = birthday.filter((member) =>
        member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    const handleMobileClick = (mobile) => {
        window.location.href = `tel:${mobile}`
    }
    const handleEmailClick = (email) => {
        window.location.href = `mailto:${email}`
    }


    return (
        <div>
            <PastorNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Birthday Celebrants</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="alert border-0 alert-warning alert-dismissible fade show" role="alert">
                            <strong>We have  {birthday.length} birthday celebrants this month. </strong>
                        </div>
                        <div className="row">
                            <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for a member' value={searchTerm} onChange={handleSearch} className='w-75 mx-auto centered-placeholder form-control mb-4' />

                            {filteredMembers.map((post) => (
                                <div className="col-lg-4">
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <div className="position-relative">

                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                <img
                                                    src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}

                                                    alt='sdbjh'
                                                    className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                            </div>
                                        </div>
                                        <div>

                                            <p className="mb-0">{post.fullName}</p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>{post.Dob}</em></p>

                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleMobileClick(post.mobile)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-phone"></i> Call
                                                </p>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleEmailClick(post.email)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-envelope"></i> Send a message
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default PastorBirthday