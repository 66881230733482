import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserNav from './UserNav';
import BaseUrl from '../BaseUrl';
import Worshipp from '../images/worship-2.jpg';
import Footer from '../LandingPage/Footer';
import { useSelector } from 'react-redux';
import avatarMan from '../images/000.png';
import avatarWoman from '../images/0111.png';

const UserConsultation = () => {
    const { loginUser: user } = useSelector((state) => state.login);
    const [availableConsultations, setAvailableConsultations] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedStartTime, setSelectedStartTime] = useState('');
    const [selectedEndTime, setSelectedEndTime] = useState('');
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const [selectedPurpose, setSelectedPurpose] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [result, setResult] = useState({
        message: '',
        status: false,
    });

    useEffect(() => {
        fetchAvailableConsultations();
    }, []);

    const fetchAvailableConsultations = async () => {
        try {
            const response = await axios.get(`${BaseUrl}availableConsultations`);
            if (response.data.status) {
                setAvailableConsultations(response.data.data);
                console.log(response.data.data);
            } else {
                console.error('Failed to fetch available consultations.');
            }
        } catch (err) {
            console.error('An error occurred while fetching available consultations:', err);
        }
    };

    const handleBookConsultation = async () => {
        setIsLoading(true);

        const memberId = user?._id;
        const fullName = user?.fullName;
        if (selectedDate && selectedStartTime && selectedEndTime && selectedUserId && selectedPurpose && selectedAdmin) {
            const newBooking = {
                userId: selectedUserId,
                memberId,
                fullName,
                date: selectedDate,
                startTime: selectedStartTime,
                endTime: selectedEndTime,
                purpose: selectedPurpose,
                adminId: selectedAdmin,
            };

            try {
                const response = await axios.post(`${BaseUrl}bookConsultation`, newBooking);
                setMessage(response.data.message);
                if (response.data.status) {
                    fetchAvailableConsultations();
                } else {
                    setError(response.data.message);
                }
                setResult({
                    message: response.data.message,
                    status: response.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: '',
                        status: false,
                    });
                    setError('');
                }, 3000);
            } catch (err) {
                console.error('An error occurred while booking the consultation:', err);
                setMessage('An error occurred while booking the consultation.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const formatTime = (time) => {
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        minutes = minutes.padStart(2, '0');
        return `${hours}:${minutes} ${period}`;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div>
            <UserNav />
            <div>
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div
                                    className="grid-item inner-box grid-cover mb-5"
                                    style={{ backgroundImage: `url(${Worshipp})` }}
                                >
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Consultation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mb-5">
                    <h3 className="text-center mt-4 mb-5">Available Consultation Times</h3>
                    <p>
                        <b className="text-danger">{error}</b>
                    </p>
                    <div
                        className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'} d-${result.message ? 'block' : 'none'}`}
                    >
                        {result.message}
                    </div>
                    <div className="row">
                        <div className='col-lg-6'>
                            <div className='row'>
                                {availableConsultations.length > 0 ? (
                                    availableConsultations.map((consultation) => (
                                        <div className="col-lg-6 mx-auto">
                                            <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                                <div className="list-group">
                                                    <div
                                                        className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3"
                                                        key={consultation._id}
                                                    >
                                                        <div className="position-relative">
                                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                                <img
                                                                    src={
                                                                        consultation?.userImageUrl ||
                                                                        (consultation?.gender === 'Male'
                                                                            ? avatarMan
                                                                            : avatarWoman)
                                                                    }
                                                                    alt="avatar"
                                                                    className="img-fluid w-100 h-100 object-fit-cover object-position-center"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="mb-0">{consultation.fullName}</p>
                                                            <p className="text-muted text-medium ft-sm mb-0">
                                                                <em>
                                                                    {formatTime(consultation.startTime)} to {formatTime(consultation.endTime)}
                                                                </em>
                                                            </p>
                                                            <p className="text-muted text-medium ft-sm mb-0">
                                                                <em>
                                                                    {capitalizeFirstLetter(consultation.purpose)} on {consultation.date}
                                                                </em>
                                                            </p>
                                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                                <button
                                                                    className="btn btn-main-1 w-100 text-medium mt-2 rounded-sm py-2 px-3"
                                                                    onClick={() => {
                                                                        setSelectedUserId(consultation._id);
                                                                        setSelectedDate(consultation.date);
                                                                        setSelectedStartTime(consultation.startTime);
                                                                        setSelectedEndTime(consultation.endTime);
                                                                        setSelectedPurpose(consultation.purpose);
                                                                        setSelectedAdmin(consultation.userId);
                                                                    }}
                                                                >
                                                                    Book
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                ) : (
                                    <li className="list-group-item">No available consultations found.</li>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 mx-auto text-center">
                            {selectedDate &&
                                selectedStartTime &&
                                selectedEndTime &&
                                selectedUserId &&
                                selectedPurpose &&
                                selectedAdmin && (
                                    <div>
                                        <h4>Selected Consultation Time</h4>
                                        <p>
                                            {capitalizeFirstLetter(selectedPurpose)} on {selectedDate} from {formatTime(selectedStartTime)} to {formatTime(selectedEndTime)}
                                        </p>
                                        <button
                                            className="btn btn-main-1  text-medium rounded-sm  py-3 px-4"
                                            disabled={isLoading}
                                            onClick={handleBookConsultation}
                                        >
                                            <b>{isLoading ? 'Submitting...' : 'Confirm Booking'}</b>
                                        </button>
                                    </div>
                                )}
                            {message && <p className="mt-4">{message}</p>}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default UserConsultation;
