import React, { useEffect, useRef, useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Footer from '../LandingPage/Footer'
import PastorNav from './PastorNav'



const Testimony = () => {
    const { LoginPastor: user } = useSelector((state) => state.pastor)
    const message = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const [test, setTest] = useState([])
    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = () => {
        axios.get(BaseUrl + `getUserTest/${user?._id}`)
            .then(data => {
                setTest(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSubmit = () => {
        const data = {
            fullName: user?.fullName,
            email: user?.email,
            mobile: user?.mobile,
            message: message.current.value,
            userId: user?._id,
        }
        const joinDate = moment().format('YYYY-MM-DD');
        const updatedValues = { ...data, joinDate: joinDate };
        setIsLoading(true);
        axios.post(BaseUrl + "userTest", updatedValues).then(
            res => {
                if (res.data.status) {
                    axios.get(BaseUrl + `getUserTest/${user?._id}`)
                        .then(data => {
                            setTest(data.data.data);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    message.current.value = '';
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })


    return (
        <div>
            <PastorNav />
            <div className="">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Testimonial</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-section pb-5">
                    <div className="container">
                        <h4 className="text-center mb-5">Thank God for what he has done in your life</h4>
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className="row mt-5">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <textarea className="form-control" ref={message} style={{ height: '100px' }} placeholder="Message"></textarea>
                                            <label>Message</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" onClick={handleSubmit} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                                <b>
                                                    {isLoading ? "Loading..." : "Submit"}
                                                </b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div style={{ height: '300px' }} className="overflow-auto bg-light p-lg-4 p-3 rounded">
                                    <h5 className="text-center mb-5">Thank God for what he has done in your life</h5>
                                    <hr />
                                    {test.map((post) => (
                                        <div className="mb-3 border-bottom">
                                            <h6>God Thank You</h6>
                                            <p className="line-clamp-3 ft-sm">{post.message}</p>
                                            <p className="text-muted text-medium ft-sm"><em>Submitted On {post.joinDate}.</em></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Testimony