import React, { useEffect, useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import { Link } from 'react-router-dom'
import ava from '../images/000.png'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import Footer from '../LandingPage/Footer'
import PastorNav from './PastorNav'



const PastorTestimonies = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [test, setTest] = useState([])


    useEffect(() => {
        FetchData();
    }, [])

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const FetchData = () => {
        axios.get(BaseUrl + "getAllTest")
            .then(data => {
                setTest(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }
    const filteredMembers = test.filter((member) =>
        member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [result, setResult] = useState({
        message: "",
        status: false
    })
    const [Error, setError] = useState("");

    const DeleteTest = (_id) => {
        axios.delete(BaseUrl + `delTest/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();

                } else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })

    };

    const approveTest = (_id) => {
        axios.post(BaseUrl + `approveTest/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })


    }



    return (
        <div>
            <PastorNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Testimonies</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="alert border-0 alert-warning alert-dismissible fade show" role="alert">
                            <h5 className="text-center ">Thank God for what he has done in your life</h5>
                        </div>
                        <input type='text' name='search' placeholder='Search for a member' value={searchTerm} onChange={handleSearch} className='w-75 mx-auto centered-placeholder form-control mb-4' />

                        <div className="row ">
                            <p>
                                <b className="text-danger">{Error}</b>
                            </p>
                            <div
                                className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                    } d-${result.message ? 'block' : 'none'}`}>
                                {result.message}
                            </div>
                            {filteredMembers.map((post) => (
                                <div className="col-lg-4">
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <div className="position-relative">

                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                <img src={post.userImageUrl || ava} alt='sdbjh' className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                            </div>

                                        </div>
                                        <div>

                                            <p className="mb-0">{post.fullName}</p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>Testimonies: {post.message}</em></p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>{post.joinDate}</em></p>

                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                <Link to={post.mobile} className="text-decoration-none text-dark">
                                                    <i className="bi bi-phone"></i> Call
                                                </Link>
                                                <Link to={post.email} className="text-decoration-none text-dark">
                                                    <i className="bi bi-envelope"></i> Send a message
                                                </Link>
                                            </div>
                                            <div className="d-flex mt-2" style={{ gap: '20px' }}>
                                                <button type="submit" name="submit" onClick={() => approveTest(post._id)} className="btn btn bg-success text-light" >
                                                    <b>
                                                        <i className="fa fa-check"></i>
                                                    </b>
                                                </button>
                                                <button type="submit" name="submit" onClick={() => DeleteTest(post._id)} className="btn btn bg-danger text-light" >
                                                    <b>
                                                        <i className="fa fa-trash"></i>
                                                    </b>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>





            </div>
            <Footer />
        </div>
    )
}

export default PastorTestimonies