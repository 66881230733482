import React, { useEffect, useRef, useState } from 'react'
import AdminNav from './AdminNav'
import Worshipp from '../images/worship-2.jpg'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Footer from '../LandingPage/Footer'

const AdminDashboard = () => {
    const { loginAdmin: user } = useSelector((state) => state.admin);

    const verifyCodee = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [codee, setCodee] = useState([])
    
    useEffect(() => {
        FetchCode();
      }, [])
  
      const FetchCode =()=>{
          axios.get(BaseUrl + "getVerifyCode")
                          .then(data => {
                              setCodee(data.data.data);
                          })
                          .catch(err => {
                              console.log(err);
                          })
      }

    const handleCode =()=>{
        const info ={
            joinDate : moment().format('YYYY-MM-DD'),
            verifyCode : verifyCodee.current.value
        }
        setIsLoading(true);
        axios.post(BaseUrl + "adminVerify", info).then(
            res => {
                if (res.data.status) {
                        verifyCodee.current.value = '';
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const [result, setResult] = useState({
        message: "",
        status: false
    })


    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Hello, Mr Admin {user?.fullName}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="info-section pb-5">
                    <div className="container">
                    <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>

                        <div className="col-lg-12">
                            

                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h3 className="mb-0">Profile</h3>
                             </div>

                            <form>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Mr" value={user?.titles} disabled />
                                            <label>Title</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Full name" value={user?.fullName} disabled />
                                            <label>Full name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" placeholder="Email address" value={user?.email} disabled />
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" placeholder="Phone number" disabled value={user?.mobile} />
                                            <label>Phone number</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" value={user?.postalCode} className="form-control" placeholder="Postal code" disabled />
                                            <label>Postal code</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating mb-3">
                                            <input type="text" value={user?.address} className="form-control" placeholder="Address" disabled />
                                            <label>Address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3">

                                        <p className="mb-0"><label>Date of Birth</label></p>
                                        <span>{user?.Dob}</span>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Gender</label></p>
                                        <span>{user?.gender}</span>
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <p className="mb-0"><label>Status</label></p>
                                        <span className="badge text-bg-success">Member</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>





            </div>
            <Footer/>
        </div>

    )
}

export default AdminDashboard