import React, { useEffect, useState } from 'react'
import AdminNav from './AdminNav'
import Worshipp from '../images/worship-2.jpg'
import avatarWoman from '../images/0111.png'
import avatarMan from '../images/000.png'
import { Link } from 'react-router-dom'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { parseISO, formatDistanceToNow } from 'date-fns';
import Footer from '../LandingPage/Footer'


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}


const AllMember = () => {
    const [members, setMembers] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermDisabled, setSearchDisabled] = useState('');

    useEffect(() => {
        // fetchData();
        getDisabledMember();
        getEnabledMember();
    }, []);

    // const fetchData = async () => {
    //     await axios.get(BaseUrl + 'getAllUser')
    //         .then(data => {
    //             setMembers(data.data.data);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // };

    const [EnabledMembers, setEnabledMembers] = useState([])
    const getEnabledMember = async () => {
        await axios.get(BaseUrl + 'getEnabledMember')
            .then(data => {
                setEnabledMembers(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    };
    const [DisabledMembers, setDisabledMembers] = useState([])
    const getDisabledMember = async () => {
        await axios.get(BaseUrl + 'getDisabledMember')
            .then(data => {
                setDisabledMembers(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const handleDisabled = (e) => {
        setSearchDisabled(e.target.value);
    };
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredDisabledMembers = DisabledMembers.filter((member) =>
        member.fullName.toLowerCase().includes(searchTermDisabled.toLowerCase())
    )
    const filteredMembers = EnabledMembers.filter((member) =>
        member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const handleMobileClick = (mobile) => {
        window.location.href = `tel:${mobile}`
    }
    const handleEmailClick = (email) => {
        window.location.href = `mailto:${email}`
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const [Error, setError] = useState("");
    const disabledMember = (_id) => {
        axios.post(BaseUrl + `disabledMember/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    // fetchData();
                    getEnabledMember();
                    getDisabledMember();

                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }


    const EnabledMember = (_id) => {
        axios.post(BaseUrl + `EnabledMember/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    // fetchData();
                    getEnabledMember();
                    getDisabledMember();

                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    return (
        <div>
            <AdminNav />
            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Members</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="alert border-0 alert-warning alert-dismissible fade show" role="alert">
                            <strong>We have some birthday celebrants this month. <Link to="/admin/All-Birthday-Celebrants" className="text-dark">View details</Link></strong>
                        </div>
                        <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for a member' value={searchTerm} onChange={handleSearch} className='w-75 mx-auto centered-placeholder form-control mb-4' />

                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>

                        <div className="row ">
                            {filteredMembers.filter((val) => val._id != members._id).sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post) => (
                                <div className="col-lg-4">
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <div className="position-relative">
                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                <img 
                                                src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}
                                                alt={post.fullName}
                                                 className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                            </div>

                                        </div>
                                        <div>

                                            <p className="mb-0">{post.titles}&nbsp;{post.fullName}</p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>Joined: {TimeAgo(post.createdAt)}</em></p>

                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleMobileClick(post.mobile)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-phone"></i> Call
                                                </p>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleEmailClick(post.email)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-envelope"></i> Message
                                                </p>
                                                <p style={{ cursor: 'pointer' }} name="submit" onClick={() => disabledMember(post._id)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-trash " style={{ color: 'red' }}></i> Suspend
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>



                        <div className="alert text-center mx-auto border-0 alert-warning alert-dismissible fade show" role="alert">
                            <strong >Disabled Members Account.</strong>
                        </div>
                        <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for a disabled member' value={searchTermDisabled} onChange={handleDisabled} className='w-75 mx-auto centered-placeholder form-control mb-4' />
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            {filteredDisabledMembers.filter((val) => val._id != members._id).sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((post) => (
                                <div className="col-lg-4">
                                    <div className="d-flex text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                                        <div className="position-relative">

                                            <div className="avatar-circle-2 flex-shrink-0 me-2">
                                                <img 
                                                src={post?.userImageUrl || (post?.gender === 'Male' ? avatarMan : avatarWoman)}
                                                 alt={post.fullName} 
                                                 className="img-fluid w-100 h-100 object-fit-cover object-position-center" />
                                            </div>

                                        </div>
                                        <div>

                                            <p className="mb-0">{post.titles} &nbsp;{post.fullName}</p>
                                            <p className="text-muted text-medium ft-sm mb-0"><em>Joined: {TimeAgo(post.createdAt)}</em></p>

                                            <div className="d-flex" style={{ gap: '20px' }}>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleMobileClick(post.mobile)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-phone"></i> Call
                                                </p>
                                                <p style={{ cursor: 'pointer' }} onClick={() => handleEmailClick(post.email)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-envelope"></i> Message
                                                </p>
                                                <p style={{ cursor: 'pointer' }} name="submit" onClick={() => EnabledMember(post._id)} className="text-decoration-none text-dark">
                                                    <i className="bi bi-check-circle" style={{ color: 'green' }}></i> Activate
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>





                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default AllMember