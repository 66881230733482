import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../images/logo.png'

const NavBar = () => {
    return (
        <div>
            <div className="top-bar bg-color-2 py-1 fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-unstyled d-flex align-items-center justify-content-end mb-0" style={{ gap: '10px' }}>
                                <li><NavLink to="/Login" className="text-white text-decoration-none">Login</NavLink></li>
                                <li><span className="text-white">|</span></li>
                                <li><Link to="/giving" className="text-white text-decoration-none">Donate</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white fixed-top nav-top-auto border-bottom">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img src={logo} alt='logo' className="img-fluid" style={{ height: "70px" }} />
                    </NavLink>
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav main-nav-item ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/About">About</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Activities
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className="nav-link" aria-current="page" to="/news">News</Link>
                                    <Link className="nav-link" aria-current="page" to="/events">Events</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Sermon">Sermon</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Gallery">Gallery</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Guest-Add-Prayer">Prayer Request</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Guest-Add-Testimonial">Testimoies</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/Contact-Us">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavBar