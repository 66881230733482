import React, { useEffect, useState } from 'react';
import NavBar from './NavBar';
import Worshipp from '../images/worship-2.jpg';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import Footer from './Footer';

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [gallery, setGallery] = useState([]);
    const [error, setError] = useState("");

    const images = [];

    useEffect(() => {
        FetchData();
    }, []);

    const FetchData = async () => {
        try {
            const response = await axios.get(BaseUrl + 'getGallery');
            if (response.data.status) {
                const fetchedImages = response.data.data.map((img, index) => ({
                    id: images.length + index + 1,
                    src: img,
                    title: `Fetched Image ${index + 1}`
                }));
                setGallery(fetchedImages);
            } else {
                setError("Failed to fetch uploaded images.");
            }
        } catch (err) {
            console.log(err.message);
            setError("An error occurred while fetching the images.");
        }
    };

    const openModal = (index) => {
        const allImages = [...images, ...gallery];
        setSelectedImage(allImages[index]);
        setCurrentIndex(index);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const showPreviousImage = () => {
        const allImages = [...images, ...gallery];
        const newIndex = (currentIndex === 0 ? allImages.length : currentIndex) - 1;
        setCurrentIndex(newIndex);
        setSelectedImage(allImages[newIndex]);
    };

    const showNextImage = () => {
        const allImages = [...images, ...gallery];
        const newIndex = (currentIndex + 1) % allImages.length;
        setCurrentIndex(newIndex);
        setSelectedImage(allImages[newIndex]);
    };

    return (
        <div>
            <NavBar />

            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Gallery</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <div className="row">
                            {[...images, ...gallery].map((image, index) => (
                                <div className="col-lg-3 col-md-4 col-xs-6 thumb" key={image.id}>
                                    <a
                                        href="#"
                                        className="thumbnail"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openModal(index);
                                        }}
                                    >
                                        <img className="img-thumbnail w-auto" src={image.src} alt={image.title} />
                                    </a>
                                </div>
                            ))}
                        </div>

                        {selectedImage && (
                            <div
                                className="modal fade show d-block"
                                tabIndex="-1"
                                role="dialog"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
                            >
                                <button
                                    type="button"
                                    className="close fs-1 ml-5"
                                    aria-label="Close"
                                    onClick={() => setSelectedImage(null)}
                                    style={{ color: 'white', position: 'absolute', top: '10px', right: '20px', zIndex: 1050 }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <div className="modal-content mx-auto mt-5 mb-5" style={{ width: '50%' }}>

                                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active text-center">
                                                <img
                                                    src={selectedImage.src}
                                                    className="img-fluid w-100 h-auto"
                                                    alt={selectedImage.title}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <a
                                    className="carousel-control-prev"
                                    href="#carouselExampleFade"
                                    role="button"
                                    data-slide="prev"
                                    onClick={showPreviousImage}
                                    disabled={currentIndex === 0}
                                >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    href="#carouselExampleFade"
                                    role="button"
                                    data-slide="next"
                                    onClick={showNextImage}
                                    disabled={currentIndex === images.length + gallery.length - 1}
                                >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        )}






                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Gallery;
