import React from 'react'
import { Route, Routes } from 'react-router'
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom'
import LandingPage from './LandingPage/LandingPage';
import About from './LandingPage/About';
import News from './LandingPage/News-And-Events';
import Sermon from './LandingPage/Sermon';
import Gallery from './LandingPage/Gallery';
import Contact from './LandingPage/Contact-Us';
import Donation from './LandingPage/Donation';
import Login from './User/Login';
import Forgotten from './User/Forgotten-Password';
import Sign from './User/Sign-Up';
import Dashboard from './User/Dashboard';
import Add from './User/Add-Prayer-Request';
import Testimonial from './User/Add-Testimonial';
import Members from './User/Members';
import axios from 'axios';
import UserGuard from './Guard/GuardUser';
import Birthday from './User/Birthday-Celebrants';
import EditUser from './User/Edit-User';
import GuestPrayer from './LandingPage/Guest-Add-Prayer';
import GuestTestimonial from './LandingPage/Guest-Add-Testimonial';
import AllMember from './Admin/AllMember';
import AllBirthday from './Admin/All-Birthday-Celebrants';
import AllTestimonies from './Admin/All-Testimonies';
import AllPrayer from './Admin/All-Prayer';
import { ToastContainer } from 'react-toastify';
import AdminSermon from './Admin/AdminSermon';
import AdminNews from './Admin/News-and-Event';
import NewsDetails from './LandingPage/News-Details';
import SermonDetails from './LandingPage/Sermon-Details';
import AdminDashboard from './Admin/AdminDashboard';
import TestDashboard from './Admin/Dashboard';
import AdminLogin from './Admin/AdminLogin';
import AdminGuard from './Guard/AdminGuard';
import AdminDepartment from './Admin/AdminDepartment';
import AdminAttendance from './Admin/AdminAttendance';
import AdminMember from './Admin/AdminMemeber-Department';
import VerificationCode from './Admin/VerificationCode';
import AddAdmin from './Admin/AddAdmin';
import AddAdminMember from './Admin/AddAdmin-Member';
import ConfirmPassword from './User/ConfirmPassword';
import ViewAllDepartment from './Admin/ViewAllDepartment';
import VerificationToken from './User/VerificationToken';
import AdminPersonalProphecies from './Admin/AdminPersonalProphecies';
import HeaderSetting from './Admin/HeaderSetting';
import GallerySetting from './Admin/GallerySetting';
import Events from './Admin/Events';
import EventsRegister from './Admin/EventsRegister';
import EventsAttendance from './Admin/EventsAttendance';
import AdminChangePassword from './Admin/AdminChangePassword';
import AdminConfirmPassword from './Admin/AdminConfirmPassword';
import UserChangePassword from './User/UserChangePassword';
import UserConfirmPassword from './User/UserConfirmPassword';
import LoginPastor from './Pastor/LoginPastor';
import PastorGuard from './Guard/PastorGuard';
import PastorProfile from './Pastor/PastorProfile';
import EditPastor from './Pastor/EditPastor';
import PastorConfirmPassword from './Pastor/PastorConfirm';
import PastorChangePassword from './Pastor/Pastor-ChangePassword';
import SuperLogin from './SuperAdmin/SuperAdminLogin';
import SuperProfile from './SuperAdmin/SuperProfile';
import SuperGuard from './Guard/SuperGuard';
import SuperConfirmPassword from './SuperAdmin/SuperConfirm';
import SuperChangePassword from './SuperAdmin/Super-ChangePassword';
import PastorConsultation from './Pastor/PastorConsultation';
import AdminConsultation from './Admin/AdminConsultation';
import UserConsultation from './User/UserConsultation';
import AdminNotifications from './Admin/AdminNotification';
import Event from './LandingPage/Events';
import EventDetails from './LandingPage/EventDetails';
import SuperAdminConsultation from './SuperAdmin/SuperAdminConsultation';
import SuperNotifications from './SuperAdmin/SuperNotification';
import PastorNotifications from './Pastor/PastorNotification';
import AllMembers from './SuperAdmin/AllMember';
import BirthdayCelebrant from './SuperAdmin/SuperBirthdayCelebrant';
import SuperNews from './SuperAdmin/SuperNews';
import SuperEvent from './SuperAdmin/SuperEvent';
import SuperAttendance from './SuperAdmin/SuperAttendance';
import PastorSermon from './Pastor/PastorSermon';
import PastorPrayer from './Pastor/AllPrayer';
import PastorTestimonies from './Pastor/AllTestimonies';
import PastorMembers from './Pastor/PastorMember';
import PastorBirthday from './Pastor/Birthday';
import Testimony from './Pastor/Testimony';
import Prayer from './Pastor/Prayer';
import SuperPrayer from './SuperAdmin/SuperPrayer';
import SuperTestimony from './SuperAdmin/SuperTestimonies';
import AdminPrayer from './Admin/AdminPrayer';
import AdminTestimony from './Admin/AdminTestimonies';
import UserNotifications from './User/UserNotification';
import UserDetails from './Admin/UserDetails';
import Details from './Pastor/Details';
import Support from './LandingPage/Support';
import DonationReceived from './LandingPage/DonationReceived';
import AdminTransactions from './Admin/AdminTransactions';
import UserDonation from './User/UserDonation';
import PastorDonation from './Pastor/PastorDonation';
import SuperDonation from './SuperAdmin/SuperDonation';
import UserTransaction from './User/UserTransaction';
import PastorTransaction from './Pastor/PastorTransaction';
import SuperTransaction from './SuperAdmin/SuperTransaction';

axios.interceptors.request.use((value) => {
  value.headers = {
    "Authorization": localStorage.token
  };
  return value;
})


const Routing = () => {
  return (
    <Router>
      <Routes>
        {/* landing page */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<News />} />
        <Route path="/events" element={<Event />} />
        <Route path="/sermon" element={<Sermon />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact-Us" element={<Contact />} />
        <Route path="/Guest-Add-Prayer" element={<GuestPrayer />} />
        <Route path="/Guest-Add-Testimonial" element={<GuestTestimonial />} />
        <Route path="/news-details" element={<NewsDetails />} />
        <Route path="/sermon-details" element={<SermonDetails />} />
        <Route path="/event-details" element={<EventDetails />} />
        <Route path="/giving" element={<Support />} />
        <Route path="/donation-received" element={<DonationReceived />} />

        {/* users */}
        <Route path="/login" element={<Login />} />
        <Route path="/become-a-member" element={<Sign />} />
        <Route path="/verify/" element={<VerificationToken />} />
        <Route path="/forgotten-password" element={<Forgotten />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />

        <Route path="/" element={<UserGuard />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/donation" element={<UserDonation />} />
          <Route path="/Edit-User" element={<EditUser />} />
          <Route path="/Add-Prayer-Request" element={<Add />} />
          <Route path="/Add-Testimonial" element={<Testimonial />} />
          <Route path="/Birthday-Celebrants" element={<Birthday />} />
          <Route path="/Members" element={<Members />} />
          <Route path="/user-change-password" element={<UserChangePassword />} />
          <Route path="/user-confirm-password" element={<UserConfirmPassword />} />
          <Route path="/user-consultation" element={<UserConsultation />} />
          <Route path="/notification" element={<UserNotifications />} />
          <Route path="/transaction" element={<UserTransaction />} />
        </Route>

        {/* admin */}
        <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/event-register/" element={<EventsRegister />} />

        <Route path='admin' element={<AdminGuard />}>
        <Route path="Donation" element={<Donation />} />
          <Route path="Admin-Dashboard" element={<AdminDashboard />} />
          <Route path="dashboard" element={<TestDashboard />} />
          <Route path="Admin-Department" element={<AdminDepartment />} />
          <Route path="Admin-Attendance" element={<AdminAttendance />} />
          <Route path="All-Members" element={<AllMember />} />
          <Route path="All-Birthday-Celebrants" element={<AllBirthday />} />
          <Route path="All-Testimonies" element={<AllTestimonies />} />
          <Route path="All-Prayer-Request" element={<AllPrayer />} />
          <Route path="Admin-Sermon" element={<AdminSermon />} />
          <Route path="News" element={<AdminNews />} />
          <Route path="AdminMember-Department" element={<AdminMember />} />
          <Route path="Verification-Code" element={<VerificationCode />} />
          <Route path="add-admin" element={<AddAdmin />} />
          <Route path="add-admin-member" element={<AddAdminMember />} />
          <Route path="view-department" element={<ViewAllDepartment />} />
          <Route path="prophecies" element={<AdminPersonalProphecies />} />
          <Route path="header-setting" element={<HeaderSetting />} />
          <Route path="gallery-setting" element={<GallerySetting />} />
          <Route path="events" element={<Events />} />
          <Route path="event-attendance" element={<EventsAttendance />} />
          <Route path="change-password" element={<AdminChangePassword />} />
          <Route path="admin-comfirmPassword" element={<AdminConfirmPassword />} />
          <Route path="consultation" element={<AdminConsultation />} />
          <Route path="notification" element={<AdminNotifications />} />
          <Route path="prayer" element={<AdminPrayer />} />
          <Route path="testimonies" element={<AdminTestimony />} />
          <Route path="transactions" element={<AdminTransactions />} />
          <Route path="userDetail" element={<UserDetails />} />
        </Route>

        {/* Pastor */}
        <Route path="/Login-Pastor" element={<LoginPastor />} />
        <Route path="pastor" element={<PastorGuard />}>
        <Route path="donation" element={<PastorDonation />} />
          <Route path="pastor-profile" element={<PastorProfile />} />
          <Route path="edit-pastor" element={<EditPastor />} />
          <Route path="change-password" element={<PastorChangePassword />} />
          <Route path="confirm-password" element={<PastorConfirmPassword />} />
          <Route path="consultation" element={<PastorConsultation />} />
          <Route path="notification" element={<PastorNotifications />} />
          <Route path="sermon" element={<PastorSermon />} />
          <Route path="allprayer" element={<PastorPrayer />} />
          <Route path="alltestimonies" element={<PastorTestimonies />} />
          <Route path="member" element={<PastorMembers />} />
          <Route path="birthday" element={<PastorBirthday />} />
          <Route path="testimony" element={<Testimony />} />
          <Route path="prayer" element={<Prayer />} />
          <Route path="details" element={<Details />} />
          <Route path="transaction" element={<PastorTransaction />} />
        </Route>

        {/* Super Admin */}
        <Route path="/super-adminLogin" element={<SuperLogin />} />
        <Route path="super" element={<SuperGuard />}>
        <Route path="donation" element={<SuperDonation />} />
          <Route path="super-profile" element={<SuperProfile />} />
          <Route path="edit-pastor" element={<EditPastor />} />
          <Route path="change-password" element={<SuperChangePassword />} />
          <Route path="confirm-password" element={<SuperConfirmPassword />} />
          <Route path="superConsultation" element={<SuperAdminConsultation />} />
          <Route path="notification" element={<SuperNotifications />} />
          <Route path="member" element={<AllMembers />} />
          <Route path="birthday" element={<BirthdayCelebrant />} />
          <Route path="new" element={<SuperNews />} />
          <Route path="event" element={<SuperEvent />} />
          <Route path="attendance" element={<SuperAttendance />} />
          <Route path="prayer" element={<SuperPrayer />} />
          <Route path="testimonies" element={<SuperTestimony />} />
          <Route path="transaction" element={<SuperTransaction />} />
        </Route>




      </Routes>
      <ToastContainer />
    </Router>
  )
}

export default Routing