import React, { useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
import stripe from '../images/stripe.webp'
import visa from '../images/visa-icon.webp'
import Mastercard from '../images/Mastercard-1.webp' 
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from "yup";
import StripePayment from '../Payment/Stripe'
import axios from 'axios'
import BaseUrl from '../BaseUrl'
import { useSelector } from 'react-redux'
import Footer from '../LandingPage/Footer'
import SuperNav from './SuperNav'

const SuperDonation = () => {
    const { LoginPastor: user } = useSelector((state) => state.pastor)
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState("");


  const formik = useFormik({
    initialValues: {
      amount: "",
      fullname: "",
      payment_type: "",
      email: "",
      currency: "GBP"
    },

    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const data = {
          fullname: user?.fullName || '',  
          email: user?.email || '',
          ...values,
          amount: Number(values.amount),             
        };
        
        axios.post(`${BaseUrl}v1/payment-intent`, data).then((response) => {
          setIsLoading(false);
          resetForm();
          setClientSecret(response.data.data.payment_client_secret);
        }).catch((error) => {
          setError("Payment could not be processed. Please try again.");
          setIsLoading(false);
        });
      } catch (error) {
        setError("Payment could not be processed. Please try again.");
        setIsLoading(false);
      }
    },

    validationSchema: yup.object({
        amount: yup.number()
            .min(0.70, "The minimum donation amount is £0.70.")
            .required("Please enter an amount."),
      payment_type: yup.string().required("Please indicate what you're donating for."),
      currency: yup.string().matches(/^(GBP|USD)$/, "Invalid currency type")
    })
  });

  const selectAmountOption = (amount) => {
    formik.setFieldValue("amount", String(amount));
  }

  return (
    <div>
      <SuperNav />
      <div className="main-content">
        <div className="about-sect">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                  <div className="overlay-hero"></div>
                  <h2 className="text-white z-index-1">Donation</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="courses-details-info-sect">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mx-auto mb-4">
                <div className="card border-3 p-lg-4 sticky-review">
                  <div className="card-body">
                    {clientSecret ? (
                      <StripePayment clientSecret={clientSecret} />
                    ) : (
                      <>
                        <h2 className="text-medium mb-4">Donate to shape a better tomorrow</h2>
                        <div className="payment-box">
                          <form method="post" onSubmit={formik.handleSubmit}>
                            <div>
                              <div className="btn-amount-list d-flex align-items-center justify-content-between flex-wrap mb-4" style={{ gap: '10px' }}>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1"
                                  data-amount="30"
                                  onClick={() => selectAmountOption(30)}
                                >£30</button>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1"
                                  data-amount="50"
                                  onClick={() => selectAmountOption(50)}
                                >£50</button>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1" data-amount="100"
                                  onClick={() => selectAmountOption(100)}
                                >£100</button>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1" data-amount="250"
                                  onClick={() => selectAmountOption(250)}
                                >£250</button>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1" data-amount="500"
                                  onClick={() => selectAmountOption(500)}
                                >£500</button>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1" data-amount="1000"
                                  onClick={() => selectAmountOption(1000)}
                                >£1000</button>
                                <button
                                  type="button"
                                  className="btn btn-lg btn-outline-dark amount-box text-medium rounded flex-grow-1" data-amount="5000"
                                  onClick={() => selectAmountOption(5000)}
                                >£5000</button>
                              </div>
                              <span className="donationAmount text-danger d-none">Amount is required</span>
                              <div className={formik.errors.amount ? "input-group is-invalid" : "input-group mb-3"}>
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text input-group-remove-py-0 bg-white"
                                    style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
                                  >£</span>
                                </div>
                                <input
                                  value={formik.values.amount}
                                  name="amount"
                                  onChange={formik.handleChange}
                                  type="text"
                                  id="donationAmount"
                                  className={formik.errors.amount ? "form-control border-start-0 form-control-num-donate is-invalid" : "form-control border-start-0 form-control-num-donate"}
                                  placeholder="How Much Would You Like To Donate"
                                />
                              </div>
                              {formik.touched.amount && (
                                <small className="text-danger">{formik.errors.amount}</small>
                              )}
                            </div>
                            <div className="form-group mt-3">
                              <select
                                name="payment_type"
                                className={formik.errors.payment_type ? "form-select my-2 is-invalid" : "form-select"}
                                onChange={formik.handleChange}
                                value={formik.values.payment_type}
                              >
                                <option value="">Donation Type</option>
                                <option value="Building">Building</option>
                                <option value="Tithe">Tithe</option>
                                <option value="Evangelism">Evangelism</option>
                                <option value="Offering">Offering</option>
                              </select>
                              {formik.touched.payment_type && (
                                <small className="text-danger">{formik.errors.payment_type}</small>
                              )}
                            </div>
                            <div>
                              <div className="my-3"></div>
                              <p>By making a donation, I agree to RCCG’s <Link to="#" className="text-medium text-dark">Terms &amp; Conditions</Link> and <Link to="#" className="text-medium text-dark">Privacy Policy</Link>.</p>
                            </div>
                            <div>
                              <div className="row mb-3">
                                <div className="col">
                                  <Link to="#" className="charity-gateway-button" data-gateway="stripe">
                                    <div className="paybox">
                                      <img src={stripe} alt='sdiu' className="img-fluid rounded object-fit-contain object-position-center w-100 h-100" />
                                    </div>
                                  </Link>
                                </div>
                                <div className="col">
                                  <Link to="#" className="charity-gateway-button" data-gateway="stripe">
                                    <div className="paybox">
                                      <img src={Mastercard} alt='sknd' className="img-fluid rounded object-fit-contain object-position-center w-100 h-100" />
                                    </div>
                                  </Link>
                                </div>
                                <div className="col">
                                  <Link to="#" className="charity-gateway-button" data-gateway="stripe">
                                    <div className="paybox">
                                      <img src={visa} alt='sknd' className="img-fluid rounded object-fit-contain object-position-center w-100 h-100" />
                                    </div>
                                  </Link>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col">
                                  <button
                                    className="btn btn-main-1 text-medium py-3 w-100 px-4 text-white"
                                    disabled={isLoading}
                                  >{isLoading ? "Processing..." : "Pay"}</button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <b className="text-danger">{error}</b>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
   
}

export default SuperDonation;