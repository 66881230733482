import React, { useRef, useState } from 'react'
import logo from '../images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import BaseUrl from '../BaseUrl';
import axios from 'axios';

const Login = () => {
    const email = useRef();
    const password = useRef();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [result, setResult] = useState({
        message: "",
        status: false
    });

    const handleLogin = (e) => {
        e.preventDefault();
        const data = {
            email: email.current.value,
            password: password.current.value
        };
        setIsLoading(true);
        axios.post(BaseUrl + "adminLogin", data)
            .then(res => {
                if (res.data.status) {
                    const name = res.data.data.admin;
                    localStorage.setItem("token", res.data.token);
                    if (name === "Admin") {
                        navigate("/admin/dashboard");
                    } else if (name === "Super Admin") {
                        navigate("/super/super-profile");
                    } else if (name === "Pastor") {
                        navigate("/pastor/pastor-profile");
                    }else{
                        navigate("/Dashboard")
                    }
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            })
            .catch(err => {
                console.error(err.message);
                setError("An error occurred during login.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

//     const email = useRef();
//   const password = useRef();
//     const [Error, setError] = useState("");
//     const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false)


//   const [result, setResult] = useState({
//     message: "",
//     status: false
//   })

//   const handleLogin = (e) => {
//     e.preventDefault();
//     const data = {
//       email: email.current.value,
//       password: password.current.value
//     }
//     setIsLoading(true);
//     axios.post(BaseUrl + "userLogin", data).then
//       (res => {
//         if (res.data.status) {
//           localStorage.setItem("token", res.data.token);
//           navigate("/Dashboard")
//         }
//         setResult({
//           message: res.data.message,
//           status: res.data.status
//         })
//         setTimeout(() => {
//           setResult({
//               message: "",
//               status: false
//           });
//           setError("");
//       }, 3000);
//       }).catch(err => {
//         console.log(err.message)
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });

//   }

  return (
    <div>
        <div className="auth-section home-banner-2 vh-100 d-flex bg-light justify-content-center align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 mx-auto">
                    <div className="card border-0 shadow-sm p-4">
                        <div className="card-body">
                            <Link className="navbar-brand d-block mb-4" to="/">
                                <img src={logo} alt='ukjbdf' className="img-fluid" style={{height: '70px'}} />
                            </Link>
                            <div className="mb-4 d-flex align-items-center justify-content-between">
                                <h3 className="mb-0">Log in</h3>
                                <Link to="/become-a-member" className="text-dark d-block text-center">Create an account</Link>
                            </div>
                            <p>
                                    <b className="text-danger">{Error}</b>
                                </p>
                                <div
                                    className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                        } d-${result.message ? 'block' : 'none'}`}>
                                    {result.message}
                                </div>
                            <form method="post" action="" encType="multipart/form-data">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="email" ref={email} required className="form-control" placeholder="Email address"/>
                                            <label>Email address</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input type="password" ref={password} required className="form-control" placeholder="Password"/>
                                            <label>Password</label>
                                        </div>          
                                        </div>
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" onClick={handleLogin}  className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                                  <b>
                                                      {isLoading ? "LoggingIn..." : "Login"}
                                                  </b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <Link to="/Forgotten-Password" className="text-dark d-block my-3">Forgotten Password</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Login