import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'

const SuperNav = () => {
    const navigate = useNavigate();
    const LogOut = () => {
        localStorage.removeItem("token");
        navigate("/Login", { replace: true })
    }
    const LogO = () => {
        navigate("/super/donation")
    }

    return (
        <div className='main-content'>
            <div className="top-bar bg-color-2 py-1 fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-unstyled d-flex align-items-center justify-content-end mb-0" style={{ gap: '10px' }}>
                                <li><span style={{ cursor: 'pointer' }} onClick={LogOut} className="text-white text-decoration-none">Log out</span></li>
                                <li><span className="text-white">|</span></li>
                                <li><span style={{ cursor: 'pointer' }} onClick={LogO} className="text-white text-decoration-none ">Donate</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white fixed-top nav-top-auto border-bottom">
                <div className="container">
                    <img src={logo} alt='jf' className="img-fluid" style={{ height: "70px" }} />
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav main-nav-item ms-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Shout-Out
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/super/prayer">Prayer Request</Link>
                                    <Link className="nav-link" aria-current="page" to="/super/testimonies">Testimonial</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Activities
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/super/new">News</Link>
                                    <Link className="nav-link" aria-current="page" to="/super/event">Events</Link>
                                    <Link className="nav-link active" aria-current="page" to="/super/attendance">Attendance</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/super/member">Members</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Account Settings
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="nav-link" aria-current="page" to="/super/transaction">Transaction</Link>
                                    <Link className="nav-link" aria-current="page" to="/super/super-profile">Profile</Link>
                                    <Link className="nav-link" aria-current="page" to="/super/change-password">Change Password</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default SuperNav