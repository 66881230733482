import { createSlice } from "@reduxjs/toolkit";

const superSlice = createSlice({
    initialState: {
        LoginSuper: {}
    },
    name: "super",
    reducers: {
        setSuper:(state,actions)=>{
            state.LoginSuper = actions.payload
        }
    }
})


export const superReducer = superSlice.reducer;
export const {setSuper } =  superSlice.actions