import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Worshipp from '../images/worship-2.jpg';
import Footer from '../LandingPage/Footer';
import AdminNav from './AdminNav';
import BaseUrl from '../BaseUrl';

const GallerySetting = () => {
    const userImageUrl = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [files, setFiles] = useState([]);
    const [gallery, setGallery] = useState([])

    const [result, setResult] = useState({
        message: "",
        status: false
    });

    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = async () => {
        try {
            const response = await axios.get(BaseUrl + 'getGallery');
            if (response.data.status) {
                setGallery(response.data.data);
            } else {
                setError("Failed to fetch uploaded images.");
            }
        } catch (err) {
            console.log(err.message);
            setError("An error occurred while fetching the images.");
        }
    };

    const handleChange = (e) => {
        setFiles([...e.target.files]);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const formData = new FormData();
        files.forEach(file => {
            formData.append('userImageUrl', file);
        });

        try {
            const res = await axios.post(`${BaseUrl}gallerysetting`, formData);
            if (res.data.status) {
                console.log(res.data.status);
                userImageUrl.current.value = "";
                setFiles([]);
            } else {
                setError(res.data.message);
            }
            setResult({
                message: res.data.message,
                status: res.data.status
            });
            setTimeout(() => {
                setResult({
                    message: "",
                    status: false
                });
                setError("");
            }, 4000);
        } catch (err) {
            console.error(err.message);
            setError("An error occurred during the upload process.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (imageUrl) => {
        try {
            const res = await axios.delete(BaseUrl + 'imageDel', { data: { imageUrl } });
            if (res.data.status) {
                FetchData();  
                setResult({
                    message: res.data.message,
                    status: true
                });
            } else {
                setError(res.data.message);
                setResult({
                    message: res.data.message,
                    status: false
                });
            }
        } catch (err) {
            console.log(err.message);
            setError("An error occurred while deleting the image.");
        }
    };

    return (
        <div>
            <AdminNav />
            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Gallery Settings</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{error}</b>
                        </p>
                        <div className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'} d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>

                        <div className="row">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className="row mt-5">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <div className="form-group row mt-4">
                                                <label className="col-md-2 fw-bold">Add Image:</label>
                                                <div className="form-group col-md-3 ml-2">
                                                    <label htmlFor="file" className="custom-file-upload">
                                                        <span className="upload-icon">
                                                            <i className="bi bi-cloud-upload-fill" style={{ fontSize: '1em', cursor: 'pointer' }}></i>
                                                        </span>
                                                        <input id="file" type="file" accept="image/*" ref={userImageUrl} onChange={handleChange} multiple className="d-none" />
                                                    </label>
                                                </div>
                                                <div className="col-md-3 show-image">
                                                    {files.map((file, index) => (
                                                        <img key={index} src={URL.createObjectURL(file)} alt="preview" style={{ width: "100%" }} />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-center mt-4">
                                            <button type="submit" disabled={isLoading} name="submit" onClick={handleSubmit} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                <b>{isLoading ? "Loading..." : "Submit"}</b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div style={{ height: '300px' }} className="overflow-auto bg-light p-lg-4 p-3 rounded">
                                    <h5 className="text-center mb-5">Gallery-Setting</h5>
                                    <hr />

                                    {gallery.length > 0 ? (
                                        gallery.map((image, index) => (
                                            <div key={index} className="mb-3 text-center mx-auto" >
                                                <img src={image} alt="uploaded" className="img-thumbnail w-50" />
                                                <button className="btn text-danger" onClick={() => handleDelete(image)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No images uploaded yet.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default GallerySetting;
