import React from 'react'
import NavBar from './NavBar'
import Worshipp from '../images/worship-2.jpg'
import { Link } from 'react-router-dom'


const About = () => {
  return (
    <div>
        <NavBar/>

    <div className="main-content">
        <div className="about-sect">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid-item inner-box grid-cover mb-5" style={{backgroundImage: `url(${Worshipp})`}}>
                            <div className="overlay-hero"></div>
                            <h2 className="text-white z-index-1">About us</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="info-section pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-9 mx-auto">
	  	            <h4 style={{textAlign: 'justify'}}><strong>Victory Centre For all Nations was inaugurated in 26th November 2000 and we are a loving and caring Church.</strong></h4>
                    <p style={{textAlign: 'justify'}}>Our vision is in line with RCCG worldwide vision and it’s a place where the word of God is preached undiluted. A miracle centre where victims in any areas of life becomes VICTORS.</p>
                    <p style={{textAlign: 'justify'}}>The Lord has been manifesting His presence with miracles, signs and wonders. Surely this is a place to belong. The Lord has been enlarging our tent with parishes in Victory Centre Oxford, Victory Centre Bedford, Victory Centre Walsall, Victory Centre Lincon, Winners’ World Birmingham.</p>
                    <p style={{textAlign: 'justify'}}>In 1952 God used a man called Rev. Josiah Akindayomi, a great warrior of righteousness, to give birth to the Redeemed Christian Church of God, destined by the Lord to take the World for Him. Rev Akindayomi passed on to glory in 1980 and under supernatural circumstances, the mantle of leadership of the church fell on Pastor E. A. Adeboye, a holder of a PhD degree in Mathematics and then a lecturer at the University of Lagos, Nigeria. He had earlier been ordained a Pastor of the church in 1975. His ambition was to become the youngest university Vice chancellor in Nigeria, but God had His plans.</p>
                    <p style={{textAlign: 'justify'}}>Upon the assumption of the office of the General Overseer in 1981, Pastor Adeboye, through God’s leading introduced several ministries and programmes that have led to a phenomenal church growth/ explosion, making the church one of the fastest growing churches in the world today. At the last count, the parishes numbered 2,000 worldwide, with about 150 parishes in North America, over 200 parishes in the Uk and parishes in Germany, Belgium, Denmark, Israel, Ireland, Hong Kong, Australia, India, the West Indies and New Zealand; all over Africa, brazil, Spain, Pakistan, China, S. Korea.</p>
                    <p style={{textAlign: 'justify'}}>A major introduction of Pastor Adeboye is the model church, in conformity with the God given prophecy that the RCCG will be used as a model church to take the full gospel of faith, holiness and biblical Christian lifestyles back to Europe, America and the rest of the world. Those has become a reality with churches still springing up by the day, worldwide.</p>
                    <p style={{textAlign: 'justify'}}>God is simultaneously doing miraculous deeds through th Holy Ghost service, a monthly all-night miracle service that gathers over 500,000 worshipers each service at the Redemption Camp, Lagos, Nigeria, with Pastor Adeboye ministering. Holy Ghost services are now held in England, Germany, Denmark, and the USA with pastor Adeboye ministering. Plans are currently on towards the hosting of the General Overseer in Belgium.</p>
                    <p style={{textAlign: 'justify'}}>History was made recently when over 5 million people gathered at the Lekki 98 program in Lagos, Nigeria, an all-night service hosted by pastor Adeboye, where hundreds of thousands of souls were saved and miracles were in full manifestation. The Holy Ghost Festival programme was also held over a 3-days period in December 1999 and December 2000 with about 20 million people in attendance on both occasions.</p>
                    <p><strong>MISSION STATEMENTS OF RCCG</strong></p>
                    <ol>
                    <li> To make Heaven.</li>
                    <li>To take as many people with us.</li>
                    <li>To have a member of RCCG in every family of all nations.</li>
                    <li>To accomplish No. 1 above, holiness will be our lifestyle.</li>
                    <li>To accomplish No. 2 and 3 above, we will plant churches within five minutes walking distance in every city and town of developing countries and within five minutes driving distance in every city and town of developed countries.</li>
                    <li>We will pursue these objectives until every Nation in the world is reached for the Lord Jesus Christ.</li>
                    </ol>
                    <div className="spacer-20"></div></div>
                </div>
            </div>
        </div>

    </div>

    
    <footer className="footer text-white py-5" style={{backgroundColor: '#222123'}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                <ul className="list-unstyled">
                    <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                    <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                </ul>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
                <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                <p className="mb-0">P.O. Box 18160</p>
                <p>Richmond, VA 23226</p>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col-lg-12">
                <div className="d-flex justify-content-between align-items-center">
                    <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                    <ul className="list-unstyled d-flex mb-0" style={{gap: '10px'}}>
                        <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                        <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                        <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<button onclick="topFunction()" id="myBtn" title="Go to top">Top</button>

    </div>
  )
}

export default About