import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Worshipp from '../images/worship-2.jpg'
import AdminNav from './AdminNav'
import moment from 'moment'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import { parseISO, formatDistanceToNow } from 'date-fns';


export const TimeAgo = (e) => {
    if (!e) return "";
    const date = parseISO(e)
    let timeStamp = formatDistanceToNow(date);
    // less than a minute === just now
    if (timeStamp == "less than a minute") return "just now";

    //  1minute ==1m ago
    if (timeStamp.substring(timeStamp.length - 2) == "te") return `${timeStamp.substring(0, timeStamp.length - 7)}min ago`;

    //   2 minutes and above == 2 or any number ms ago
    if (timeStamp.substring(timeStamp.length - 2) == "es") return `${timeStamp.substring(0, timeStamp.length - 8)}mins ago`;

    // If the time is up hour it we add about at front of the time. remove `About` from the time it will just return the time 
    if (timeStamp.substring(0, 5) == "about") timeStamp = timeStamp.substring(5);

    //  changing the time from  Hour to hr ago;
    if (timeStamp.substring(timeStamp.length - 2) == "ur") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 5)}hr`;

    //  changing the time from hours to hrs ago;
    if (timeStamp.substring(timeStamp.length - 2) == "rs") return timeStamp = `${timeStamp.substring(0, timeStamp.length - 6)}hrs ago`;

    return `${timeStamp} ago `
}


const ITEMS_PER_PAGE = 5;

const AdminAttendance = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [isDelete, setIsDeleting] = useState(false)
    const [editID, setEditID] = useState('')
    const [Error, setError] = useState("");

    const [user, setUser] = useState({
        children: "",
        adult: "",
        serviceType: "",
        eventDate: "",
    })
    const [attendance, setAttendance] = useState([])
    const [test, setTest] = useState([])
    useEffect(() => {
        FetchData();
        FetchAttendance();
    }, [])



    const FetchData = () => {
        axios.get(BaseUrl + 'getAttendance')
            .then(res => {
                setTest(res.data.data.map((val, index) => { return { ...val, index } }));
                // console.log(res.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const FetchAttendance = () => {
        axios.get(BaseUrl + 'getEventRegister')
            .then(res => {
                setAttendance(res.data.data);
                // console.log(res.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const inputIsChanging = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser({ ...user, [name]: value })
    }

    const HandleSubmit = (e) => {
        e.preventDefault(e);
        const joinDate = moment().format('YYYY-MM-DD');
        const updatedValues = { ...user, joinDate: joinDate };

        setIsLoading(true);
        if (editID) {
            axios.patch(BaseUrl + `EditAttend/${editID}`, updatedValues).then((res) => {
                if (res.data.status) {
                    FetchData();
                    setUser({
                        children: "",
                        adult: "",
                        serviceType: "",
                        eventDate: "",
                    });
                }
                else {

                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            }).catch((err) => {
                console.log(err.message);
            })
                .finally(() => {
                    setIsLoading(false);
                });

        } else {
            axios.post(BaseUrl + "addAttendance", updatedValues).then(
                res => {
                    if (res.data.status) {
                        FetchData();
                        setUser({
                            serviceType: "",
                            eventDate: "",
                            children: "",
                            adult: "",
                        });
                    }
                    else {
                        setError(res.data.message);
                    }
                    setResult({
                        message: res.data.message,
                        status: res.data.status
                    })
                    setTimeout(() => {
                        setResult({
                            message: "",
                            status: false
                        });
                        setError("");
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    // / search box
    const [searchMember, setSearchMember] = useState('');
    const handleMember = (e) => {
        setSearchMember(e.target.value);
    };
    const filteredDepartment = test.filter((member) =>
        member.eventDate.toLowerCase().includes(searchMember.toLowerCase()) ||
        member.serviceType.toLowerCase().includes(searchMember.toLowerCase())
    )


    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const EditAttend = (_id) => {

        // setEdit(true);
        setEditID(_id)
        setUser(() => test.find((val) => val._id == _id))
    }

    const DeleteAttend = (_id) => {
        setIsDeleting(true);
        axios.delete(BaseUrl + `DeleteAttendance/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();
                } else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };


    const [currentPage, setCurrentPage] = useState(1);

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return filteredDepartment.slice(startIndex, endIndex);
    };

    return (
        <div>
            <AdminNav />
            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Church Attendance</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <form method="post" action="" onSubmit={HandleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <select
                                                    name='serviceType'
                                                    required
                                                    onChange={inputIsChanging}
                                                    value={user?.serviceType}
                                                    className="form-control my-2" >
                                                    <option selected>--Select--</option>
                                                    <option value="Sunday School">Sunday School</option>
                                                    <option value="Sunday Service">Sunday Service</option>
                                                    <option value="Digging Deep">Digging Deep</option>
                                                </select>
                                                <label>Service Type</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="date"
                                                    className='form-control my-2'
                                                    name="eventDate"
                                                    value={user?.eventDate}
                                                    placeholder="Enter Event Date"
                                                    onChange={inputIsChanging}
                                                    required
                                                />
                                                <label>Date of Event</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <input type='number' name='adult' value={user.adult} className='form-control ' onChange={inputIsChanging} />
                                                <label>Adult Total</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating mb-3">
                                                <input type='number' name='children' value={user.children} className='form-control' onChange={inputIsChanging} />
                                                <label>Children Total</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center mt-4">
                                                <button disabled={isLoading} type="submit" name='submit' className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4">
                                                    {isLoading ? "Loading..." : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>


                            <div className="col-lg-6">
                                <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for Event Date or Service Type' value={searchMember} onChange={handleMember} className='w-100 mx-auto centered-placeholder form-control m-2' /><br />

                                <div className=" text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">

                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th scope="col">S/N</th>
                                                <th scope="col">Event Date</th>
                                                <th scope="col">ServiceType</th>
                                                <th scope="col">Adult</th>
                                                <th scope="col">Children</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getPaginatedData().map((post, index) => (
                                                <tr key={index}>
                                                    <td>{post.index + 1}</td>
                                                    <td>{post.eventDate}</td>
                                                    <td>{post.serviceType}</td>
                                                    <td>{post.adult}</td>
                                                    <td>{post.children}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            onClick={() => EditAttend(post._id)}
                                                            className="btn btn text-success"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            disabled={isDelete}
                                                            onClick={() => DeleteAttend(post._id)}
                                                            className="btn btn text-danger mr-2"
                                                        >
                                                            <b>{isDelete ? "..." : <i className="fa fa-trash"></i>}</b>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-lg-12 mt-3">
                                    <nav>
                                        <ul className="pagination">
                                            {[...Array(Math.ceil(test.length / ITEMS_PER_PAGE)).keys()].map((num) => (
                                                <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
                                                        {num + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>





                            {/*  */}

                        </div>
                    </div>
                </div>

            </div>



            <footer className="footer text-white py-5" style={{ backgroundColor: "#222123" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Sunday</h6>
                            <p>9AM & 10:45 AM IN PERSON 11AM ONLINE</p>
                            <p>*No Services 12/31. See you back on Sunday 1/07 at 9 or 10:45am!</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">FIND US</h6>
                            <p>2 Gloucester Road, Off Manor Road, Luton LU1 3HX, Bedfordshire, United Kingdom</p>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">Email Us</h6>
                            <ul className="list-unstyled">
                                <li><Link to="mailto:admin@victorycenter.org.uk" className="text-white text-decoration-none">admin@victorycenter.org.uk</Link></li>
                                <li><Link to="mailto:pastordoherty@victorycenter.org.uk" className="text-white text-decoration-none">pastordoherty@victorycenter.org.uk</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 mb-lg-0 mb-3">
                            <h6 className="text-medium text-uppercase text-white">MAIL TO</h6>
                            <p className="mb-0">P.O. Box 18160</p>
                            <p>Richmond, VA 23226</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>© 2024 RCCG Victory Center Luton. All Rights Reserved</div>

                                <ul className="list-unstyled d-flex mb-0" style={{ gap: "10px" }}>
                                    <li><Link to="#" className="text-white"><i className="bi bi-facebook"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-instagram"></i></Link></li>
                                    <li><Link to="#" className="text-white"><i className="bi bi-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default AdminAttendance