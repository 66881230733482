import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

const PaymentForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const processPayment = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsSubmitting(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/donation-received`,
      },
    });

    if (result.error) {
      setIsSubmitting(false);
      alert(result.error.message)
    } else {
    }
  };

  return (
    <>
      <form onSubmit={processPayment}>
        <PaymentElement />
        <button
          className="btn btn-main-1 text-medium py-3 w-100 px-4"
          disabled={!stripe || isSubmitting}
        >{isSubmitting ? "Processing" : "Pay"}</button>
      </form>
    </>
  )
}

export default PaymentForm;