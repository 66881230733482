// import React, { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import axios from 'axios';
// import BaseUrl from '../BaseUrl';
// import AdminNav from './AdminNav';
// import Footer from '../LandingPage/Footer';
// import Worshipp from '../images/worship-2.jpg';

// const ITEMS_PER_PAGE = 2;

// const EventsAttendance = () => {
//     const [Event, setEvent] = useState([]);
//     const [searchParams] = useSearchParams();

//     useEffect(() => {
//         FetchData();
//     }, [searchParams]);

//     const FetchData = () => {
//         axios.get(`${BaseUrl}eventDetails/${searchParams.get('userId')}`)
//             .then(res => {
//                 if (res.data.status) {
//                     setEvent(res.data.data);
//                     console.log(res.data.data);
//                 }
//             })
//             .catch(err => {
//                 console.log(err);
//             });
//     };

//     const [searchMember, setSearchMember] = useState('');
//     const handleMember = (e) => {
//         setSearchMember(e.target.value);
//     };
//     const filteredDepartment = Event.filter((member) =>
//         member.firstName.toLowerCase().includes(searchMember.toLowerCase()) ||
//         member.lastName.toLowerCase().includes(searchMember.toLowerCase())
//     )

//     const [currentPage, setCurrentPage] = useState(1);

//     const getPaginatedData = () => {
//         const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
//         const endIndex = startIndex + ITEMS_PER_PAGE;
//         return filteredDepartment.slice(startIndex, endIndex);
//     };



//     return (
//         <div>
//             <AdminNav />

//             <div className="main-content">
//                 <div className="about-sect">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-12">
//                                 <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
//                                     <div className="overlay-hero"></div>
//                                     <h2 className="text-white z-index-1">Events</h2>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-6 mx-auto">
//                     <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for Department' value={searchMember} onChange={handleMember} className='w-100 mx-auto centered-placeholder form-control m-2' /><br />

//                     <div className="text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
//                         The number of registered people is {Event.length}
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th scope="col">S/N</th>
//                                     <th scope="col">FirstName</th>
//                                     <th scope="col">LastName</th>
//                                     <th scope="col">Email</th>
//                                     <th scope="col">Phone Number</th>
//                                     <th scope="col">Social Media</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {getPaginatedData.length > 0 ? (
//                                     getPaginatedData.map((post, index) => (
//                                         <tr key={index}>
//                                             <td>{index + 1}</td>
//                                             <td>{post.firstName}</td>
//                                             <td>{post.lastName}</td>
//                                             <td>{post.email}</td>
//                                             <td>{post.phoneNumber}</td>
//                                             <td>{post.socialMedia}</td>
//                                         </tr>
//                                     ))
//                                 ) : (
//                                     <tr>
//                                         <td colSpan="6">No events found.</td>
//                                     </tr>
//                                 )}

//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//                 <div className="col-lg-12 mt-3">
//                     <nav>
//                         <ul className="pagination">
//                             {[...Array(Math.ceil(Event.length / ITEMS_PER_PAGE)).keys()].map((num) => (
//                                 <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
//                                     <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
//                                         {num + 1}
//                                     </button>
//                                 </li>
//                             ))}
//                         </ul>
//                     </nav>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// }

// export default EventsAttendance;

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import AdminNav from './AdminNav';
import Footer from '../LandingPage/Footer';
import Worshipp from '../images/worship-2.jpg';

const ITEMS_PER_PAGE = 2;

const EventsAttendance = () => {
    const [event, setEvent] = useState([]);
    const [searchParams] = useSearchParams();
    const [searchMember, setSearchMember] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData();
    }, [searchParams]);

    const fetchData = () => {
        axios.get(`${BaseUrl}eventDetails/${searchParams.get('userId')}`)
            .then(res => {
                if (res.data.status) {
                    setEvent(res.data.data);
                    console.log(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleMember = (e) => {
        setSearchMember(e.target.value);
    };

    const filteredDepartment = event.filter((member) =>
        member.firstName.toLowerCase().includes(searchMember.toLowerCase()) ||
        member.lastName.toLowerCase().includes(searchMember.toLowerCase())
    );

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return filteredDepartment.slice(startIndex, endIndex);
    };

    return (
        <div>
            <AdminNav />
            <div className="main-content">
                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">Events</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mx-auto">
                    <input type='text' name='search' style={{ textAlign: 'center' }} placeholder='Search for People' value={searchMember} onChange={handleMember} className='w-100 mx-auto centered-placeholder form-control m-2' /><br />
                    <div className="text-decoration-none text-dark p-3 d-block border-radius-15 bg-light mb-3">
                        The number of registered people is {event.length}
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">FirstName</th>
                                    <th scope="col">LastName</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone Number</th>
                                    <th scope="col">Social Media</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPaginatedData().length > 0 ? (
                                    getPaginatedData().map((post, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{post.firstName}</td>
                                            <td>{post.lastName}</td>
                                            <td>{post.email}</td>
                                            <td>{post.phoneNumber}</td>
                                            <td>{post.socialMedia}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No events found.</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <nav>
                        <ul className="pagination">
                            {[...Array(Math.ceil(event.length / ITEMS_PER_PAGE)).keys()].map((num) => (
                                <li key={num + 1} className={`page-item ${currentPage === num + 1 ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => setCurrentPage(num + 1)}>
                                        {num + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EventsAttendance;
