import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import { useFormik } from 'formik';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import * as yup from "yup";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Swal from 'sweetalert2';


const Sign = () => {

    const [isChecked, setIsChecked] = useState(false);

    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);

    //     if (event.target.checked) {
    //         Swal.fire({
    //             title: 'Confirm',
    //             html: '<div>Accept this</div>',
    //             icon: 'info',
    //             showCancelButton: true,
    //             confirmButtonText: 'Accept',
    //             cancelButtonText: 'Cancel'
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 Swal.fire('Accepted!', '', 'success');
    //             } else {
    //                 setIsChecked(false); // Uncheck if canceled
    //             }
    //         });
    //     }
    // };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIsChecked(isChecked);

        if (isChecked) {
            Swal.fire({
                title: 'Confirm GDPR Consent',
                html: `
               <div>
                    <p><strong>Your Privacy Matters:</strong> By consenting, you agree that:</p>
                    <ul style="text-align:left;">
                        <li><strong>Data Use:</strong> We collect your details to keep you informed of church activities and updates.</li>
                        <li><strong>Data Security:</strong> Your information will be kept secure and confidential, following GDPR guidelines.</li>
                        <li><strong>Your Rights:</strong> You can access, update, or request deletion of your data at any time.</li>
                        <li><strong>Consent Withdrawal:</strong> You can withdraw your consent whenever you choose, and your data will be removed.</li>
                    </ul>
                </div>
            `,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Accept',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire('Consent Accepted!', '', 'success');
                } else {
                    formik.setFieldValue("checkBox", false); // Uncheck the checkbox if canceled
                    setIsChecked(false);
                }
            });
        }
    };


    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    let lower = new RegExp(`(?=.*[a-z])`);
    let upper = new RegExp(`(?=.*[A-Z])`);
    let number = new RegExp(`(?=.*[0-9])`);
    let length = new RegExp(`(?=.{8,})`);
    const [isLoading, setIsLoading] = useState(false)
    const [Error, setError] = useState("");
    const [result, setResult] = useState({
        message: "",
        status: false
    })


    const formik = useFormik({
        initialValues: {
            titles: "",
            fullName: "",
            email: "",
            mobile: "",
            postalCode: "",
            address: "",
            Dob: "",
            gender: "",
            code: "",
            password: "",
            checkBox: false, // add this
        },
        
        onSubmit: (values, { resetForm }) => {
            const joinDate = moment().format('YYYY-MM-DD');
            const updatedValues = { ...values, joinDate: joinDate };
            setIsLoading(true);
            axios.post(BaseUrl + "userRegister", updatedValues).then(
                res => {
                    if (res.data.status) {

                        setResult({
                            message: res.data.message,
                            status: res.data.status
                        })
                        setTimeout(() => {
                            setResult({
                                message: "",
                                status: false
                            });
                            setError("");
                            resetForm();
                        }, 3000);
                    }
                })
                .catch((err) => {
                    console.error(err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },

        // validationSchema: yup.object({
        //     titles: yup
        //         .string()
        //         .required("This field is required"),
        //     fullName: yup
        //         .string()
        //         .required("Fullname field is required")
        //         .min(3, "Must be greater than 5"),
        //     email: yup
        //         .string()
        //         .email("Must be a valid email")
        //         .required("Email field is required")
        //         .min(11, "Must be greater than 11")
        //         .test('is-gmail', 'Only Gmail accounts are allowed', function (value) {
        //             const gmailRegex = /^[a-zA-Z0-9._-]+@gmail.com$/;
        //             return gmailRegex.test(value);
        //         }),
        //     mobile: yup
        //         .string()
        //         .required("required")
        //         .matches(phoneRegExp, 'Phone number is not valid')
        //         .min(10, "too short")
        //         .max(11, "too long"),
        //     postalCode: yup
        //         .string()
        //         .required("Postal Code is required")
        //         .min(5, "too short")
        //         .max(8, "too long"),
        //     address: yup
        //         .string()
        //         .required("Address field is required")
        //         .min(5, "Must be greater than 6"),
        //     Dob: yup
        //         .date()
        //         .required("Date Of Birth field is required")
        //         .test('age', 'Must be at least 16 years old', function (value) {
        //             const currentDate = moment();
        //             const dob = moment(value, 'YYYY-MM-DD');
        //             const age = currentDate.diff(dob, 'years');
        //             return age >= 16;
        //         }),
        //     gender: yup
        //         .string()
        //         .required("Gender field is required"),
        //     checkBox: yup
        //         .string()
        //         .required("Click the checkbox is required"),
        //     code: yup
        //         .string()
        //         .required("Verification Code field is required"),
        //     password: yup
        //         .string()
        //         .matches(lower, "Must include lowercase character")
        //         .matches(upper, "Must include uppercase character")
        //         .matches(number, "Must include number")
        //         .matches(length, "Must be atleast 8 character")
        //         .required("This field is required")
        //         .min(6, "Must be greater than 6"),
        // }),
        validationSchema: yup.object({
            titles: yup.string().required("This field is required"),
            fullName: yup.string().required("Fullname field is required").min(3, "Must be greater than 5"),
            email: yup.string().email("Must be a valid email").required("Email field is required").min(11, "Must be greater than 11")
                .test('is-gmail', 'Only Gmail accounts are allowed', function (value) {
                    const gmailRegex = /^[a-zA-Z0-9._-]+@gmail.com$/;
                    return gmailRegex.test(value);
                }),
            mobile: yup.string().required("required").matches(phoneRegExp, 'Phone number is not valid').min(10, "too short").max(11, "too long"),
            postalCode: yup.string().required("Postal Code is required").min(5, "too short").max(8, "too long"),
            address: yup.string().required("Address field is required").min(5, "Must be greater than 6"),
            Dob: yup.date().required("Date Of Birth field is required")
                .test('age', 'Must be at least 16 years old', function (value) {
                    const currentDate = moment();
                    const dob = moment(value, 'YYYY-MM-DD');
                    const age = currentDate.diff(dob, 'years');
                    return age >= 16;
                }),
            gender: yup.string().required("Gender field is required"),
            code: yup.string().required("Verification Code field is required"),
            password: yup.string().matches(lower, "Must include lowercase character")
                .matches(upper, "Must include uppercase character")
                .matches(number, "Must include number")
                .matches(length, "Must be at least 8 characters")
                .required("This field is required")
                .min(6, "Must be greater than 6"),
            checkBox: yup.boolean().oneOf([true], "You must accept this to proceed"), // updated checkbox validation
        }),
        
    });



    return (
        <div>
            <div className="auth-section vh-100 d-flex bg-light justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="card signup-card border-0 shadow-sm p-4">
                                <div className="card-body">
                                    <Link className="navbar-brand d-block mb-4" to="/">
                                        <img src={logo} alt='drkj' className="img-fluid" style={{ height: '70px' }} />
                                    </Link>
                                    <div className="mb-4 d-flex align-items-center justify-content-between">
                                        <h3 className="mb-0">Become a Member</h3>
                                        <Link to="/Login" className="text-dark d-block text-center">Go to Login</Link>
                                    </div>
                                    <p>
                                        <b className="text-danger">{Error}</b>
                                    </p>
                                    <div
                                        className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                            } d-${result.message ? 'block' : 'none'}`}>
                                        {result.message}
                                    </div>

                                    <form method="post" action="" onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <select
                                                        name='titles'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.titles}
                                                        onBlur={formik.handleBlur}
                                                        className={formik.errors.titles ? "form-control my-2 is-invalid" : "form-control my-2"} >
                                                        <option selected>--Choose--</option>
                                                        <option value="Prof">Prof</option>
                                                        <option value="Dr">Dr</option>
                                                        <option value="Engr">Engr</option>
                                                        <option value="Dn">Dn</option>
                                                        <option value="Dns">Dns</option>
                                                        <option value="Mr">Mr</option>
                                                        <option value="Mrs">Mrs</option>
                                                        <option value="Miss">Miss</option>
                                                    </select>
                                                    {formik.touched.titles && (
                                                        <small className="text-danger">{formik.errors.titles}</small>
                                                    )}
                                                    <label>Title</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        className={
                                                            formik.errors.fullName
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="fullName"
                                                        placeholder="Enter your Full Name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.fullName}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.fullName && (
                                                        <small className="text-danger">{formik.errors.fullName}</small>
                                                    )}
                                                    <label>Full name</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="email"
                                                        className={
                                                            formik.errors.email
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="email"
                                                        placeholder="Enter Your Email"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.email && (
                                                        <small className="text-danger">{formik.errors.email}</small>
                                                    )}
                                                    <label>Email Address</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="number"
                                                        className={
                                                            formik.errors.mobile
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="mobile"
                                                        placeholder="Enter Your Mobile Number"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.mobile}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.mobile && (
                                                        <small className="text-danger">{formik.errors.mobile}</small>
                                                    )}
                                                    <label>Mobile number</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        className={
                                                            formik.errors.postalCode
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="postalCode"
                                                        placeholder="Enter Your Postal Code"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.postalCode}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.postalCode && (
                                                        <small className="text-danger">{formik.errors.postalCode}</small>
                                                    )}
                                                    <label>Postal code</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        className={
                                                            formik.errors.address
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="address"
                                                        placeholder="Enter Your Address"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.address}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.address && (
                                                        <small className="text-danger">{formik.errors.address}</small>
                                                    )}
                                                    <label>Address</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="date"
                                                        className={
                                                            formik.errors.Dob
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="Dob"
                                                        placeholder="Enter Your Date Of Birth"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.Dob}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.Dob && (
                                                        <small className="text-danger">{formik.errors.Dob}</small>
                                                    )}
                                                    <label>Date of Birth</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <select
                                                        name='gender'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.gender}
                                                        onBlur={formik.handleBlur}
                                                        className={formik.errors.gender ? "form-control my-2 is-invalid" : "form-control my-2"} >
                                                        <option selected>--Choose--</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    {formik.touched.gender && (
                                                        <small className="text-danger">{formik.errors.gender}</small>
                                                    )}
                                                    <label for="floatingSelect">Gender</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        className={
                                                            formik.errors.code
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="code"
                                                        placeholder="Enter Your Verification Code"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.code}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.code && (
                                                        <small className="text-danger">{formik.errors.code}</small>
                                                    )}
                                                    <label>Verification Code</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="password"
                                                        className={
                                                            formik.errors.password
                                                                ? "form-control my-2 is-invalid"
                                                                : "form-control my-2"
                                                        }
                                                        name="password"
                                                        placeholder="Enter Your Password"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.password}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.password && (
                                                        <small className="text-danger">
                                                            {formik.errors.password}
                                                        </small>
                                                    )}
                                                    <label>Password</label>
                                                </div>
                                            </div>


                                            {/* <div className="col-lg-12">
                                                <div className="form-check">
                                                    <input
                                                        // "form-check-input"
                                                        type="checkbox"
                                                        className={
                                                            formik.errors.checkBox
                                                                ? " my-2 is-invalid"
                                                                : " my-2"
                                                        }
                                                        id="flexCheckDefault"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Would like us to keep this information.
                                                    </label>
                                                </div>
                                                {formik.touched.checkBox && (
                                                        <small className="text-danger">
                                                            {formik.errors.checkBox}
                                                        </small>
                                                    )}
                                            </div> */}

<div className="col-lg-12">
    <div className="form-check">
        <input
            type="checkbox"
            className={formik.errors.checkBox ? " my-2 is-invalid" : " my-2"}
            id="flexCheckDefault"
            checked={formik.values.checkBox}
            onChange={(e) => {
                formik.setFieldValue("checkBox", e.target.checked);
                handleCheckboxChange(e);
            }}
            onBlur={formik.handleBlur}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
            &nbsp; We value your privacy and are committed to protecting your personal information. By providing your consent, you agree to these terms
        </label>
    </div>
    {formik.touched.checkBox && (
        <small className="text-danger">
            {formik.errors.checkBox}
        </small>
    )}
</div>



                                            <div className="col-lg-12">
                                                <div className="text-center mt-4">
                                                    <button type="submit" disabled={isLoading} name="submit" className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                                        <b>
                                                            {isLoading ? "Loading..." : "Submit"}
                                                        </b>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </form>

                                    <Link to="/Login" className="text-dark d-block my-3">I have an account, Log in</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sign