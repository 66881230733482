import React, { useEffect, useRef, useState } from 'react'
import Worshipp from '../images/worship-2.jpg'
// import { Link } from 'react-router-dom'
import ava from '../images/000.png'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
import moment from 'moment'
import AdminNav from './AdminNav'
import ReactQuill from 'react-quill'
import Footer from '../LandingPage/Footer'



const AdminNews = () => {

    const [file, setFile] = useState();
    function handleChange(e) {
        // console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const title = useRef();
    const message = useRef();
    const [Error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const userImageUrl  = useRef()
    const [editID,setEditID] = useState('')
    const [news, setNews] = useState([])
    useEffect(() => {
        FetchData();
    }, [])
    const FetchData = () => {
        axios.get(BaseUrl + 'getNews')
            .then(data => {
                setNews(data.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSubmit = () => {
        const data = new FormData();
                data.append("title", title.current.value)
                // data.append("message", message.current.value)
                data.append("message",message.current.getEditor().root.innerHTML)
                data.append("userImageUrl", userImageUrl.current.files[0])
        const joinDate = moment().format('YYYY-MM-DD');
        data.append( "joinDate", joinDate );
        setIsLoading(true);
        
        if(editID){
            axios.patch(BaseUrl +`EditNews/${editID}`,data).then((res)=>{
                if(res.data.status){
                    FetchData();
                        setUser({
                            title: "",
                            message: "",
                        });
                    userImageUrl.current.value = '';
                    setEditID('')
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            }).catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });

        }
        else{
            
            setIsLoading(true);
        axios.post(BaseUrl + "adminNews", data).then(
            res => {
                if (res.data.status) {
                    FetchData();
                    title.current.value = '';
                    message.current.getEditor().root.innerHTML = '';
                    userImageUrl.current.value = '';
                }
                else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status
                })
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    }
    const [result, setResult] = useState({
        message: "",
        status: false
    })

    const DeleteNews = (_id) => {
        axios.delete(BaseUrl + `delNews/${_id}`)
            .then((res) => {
                if (res.data.status) {
                    FetchData();
                } else {
                    setError(res.data.message);
                }
                setResult({
                    message: res.data.message,
                    status: res.data.status,
                });
                setTimeout(() => {
                    setResult({
                        message: "",
                        status: false,
                    });
                    setError("");
                }, 3000);
            })
            .catch((err) => {
                console.log(err.message);
            })
        };

        const [user, setUser] = useState([])


const inputIsChanging = (e) => {
    const name = e.target.name
    const value = e.target.value
    // console.log(value)
    setUser({ ...user, [name]: value })
}
const EditNews = (_id) => {

    // setEdit(true);
    setEditID(_id)
    setUser(()=>news.find((val)=>val._id==_id))
    
}
    


    return (
        <div>
            <AdminNav />

            <div className="main-content">

                <div className="about-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="grid-item inner-box grid-cover mb-5" style={{ backgroundImage: `url(${Worshipp})` }}>
                                    <div className="overlay-hero"></div>
                                    <h2 className="text-white z-index-1">News</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-section pb-5">
                    <div className="container">
                        <p>
                            <b className="text-danger">{Error}</b>
                        </p>
                        <div
                            className={`alert alert-${result.status ? 'success' : 'danger'} text-center text-${result.status ? 'success' : 'danger'
                                } d-${result.message ? 'block' : 'none'}`}>
                            {result.message}
                        </div>
                        <div className="row ">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className="row mt-5">
                                    <div className="col-lg-12">
                                        <div className="form-floating mb-3">
                                            <input name='title' type='text' className="form-control" ref={title} onChange={inputIsChanging} value={user?.title} placeholder="News and Events Title"/>
                                            <label>Title</label>
                                        </div>
                                        <div className="form-floating mb-5">
                                        <ReactQuill
                                                theme="snow"
                                                ref={message}
                                                value={user?.message || ''}
                                                onChange={(value) => inputIsChanging({ target: { name: 'message', value } })}
                                                style={{ height: '300px' }}
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['link', 'image'],
                                                        ['clean']
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <div className="form-floating mb-3">
                                            <div class="form-group row mt-4">
                                                <label class="col-md-2 fw-bold">Add Image:</label>
                                                <div class="col-md-3 show-image">
                                                    <img src={user?.userImageUrl ? user?.userImageUrl: file ? file: ava }  alt='eee' height="200" width="150" />
                                                    <em class="text-center ">Show Image Upload</em> 
                                                </div>
                                                <div class=" form-group col-md-3   ml-2">
                                                    <label for="file-upload" class="custom-file-upload btn btn">
                                                        <input id="file" type="file"  ref={userImageUrl} onChange={handleChange} />
                                                    </label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center mt-4">
                                                <button type="submit" disabled={isLoading} name="submit" onClick={handleSubmit} className="btn btn-main-1 w-100 text-medium rounded-0 py-3 px-4" >
                                                    <b>
                                                        {isLoading ? "Loading..." : "Submit"}
                                                    </b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div style={{ height: '300px' }} className="overflow-auto bg-light p-lg-4 p-3 rounded">
                                        <h5 className="text-center mb-5">News</h5>
                                        <hr />
            {news.sort((a,b)=>b.createdAt.localeCompare(a.createdAt)).map((post) => (
                                            <div className="mb-3 border-bottom">
                                                <h6>{post.title}</h6>
                                 <p className="line-clamp-3 ft-lg " dangerouslySetInnerHTML={{ __html: post.message }}></p>
                                                <p className="text-muted text-medium ft-sm"><em>Published on {post.joinDate}.</em></p>
                                                <button type="submit"  name="submit" onClick={() => DeleteNews(post._id)} className="btn btn text-danger" >
                                                     <i className="fa fa-trash"></i>
                                                </button>
                                                <button type="submit" name="submit" onClick={() =>EditNews(post._id)} className="btn btn text-success  mr-2" >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
















            </div>
            )
}

            export default AdminNews;